header.admin-header {
  height: $header-height;
  border-bottom: solid 1px $grey;

  .container {
    max-width: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.75rem;
    width: 100%;
    //max-width: $container-width;
    margin: 0;
    height: 100%;

    @media screen and (max-width: 1125px) {
      padding: 0 2rem;
    }
  }

  .col {
    display: flex;
    align-items: center;
  }

  .page-head {
    h2 {
      font-size: 1.4375rem;
      font-weight: 600;
      margin: 0 5px 0 0;
      max-width: 900px;

      @include long-text-ellipsis;

      @media screen and (max-width: 1640px) {
        max-width: 740px;
      }
      @media screen and (max-width: 1450px) {
        max-width: 600px;
      }
      @media screen and (max-width: 1350px) {
        max-width: 400px;
      }
      @media screen and (max-width: 1350px) {
        max-width: 350px;
      }
      @media screen and (max-width: 960px) {
        max-width: 200px;
      }
      @media screen and (max-width: 1125px) {
        font-size: 1.1rem;
      }
    }

    .title-row {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;

      .icon {
        margin-left: 4px;
      }

      @media screen and (max-width: 1125px) {
        margin-bottom: 0;
      }
    }

    .action {
      position: relative;
      margin-right: 1rem;

      .dropdown-component {
        min-width: 183px;
        border-radius: $border-radius;

        li {
          border-bottom: solid 1px $border-color;

          &:last-child {
            border: none;
          }
        }
      }

      .more-btn {
        @extend .button-reset;
        width: 1.875rem;
        height: 1.875rem;
        background: url(/images/icons/icon-ellipsis-dark.svg) no-repeat center;
        background-size: 85%;
      }
    }

    .breadcrumbs {
      @media screen and (max-width: 1125px) {
        position: absolute;
        margin-top: 1.2em;
      }

      ul {
        margin: 0;
        display: flex;
      }

      li {
        list-style: none;
        display: flex;
        color: $text-color;
        font-size: 1rem;
        margin: 0;
        align-items: center;

        @media screen and (max-width: 1125px) {
          font-size: 0.75rem;
        }

        & + li {
          margin-left: 1rem;

          &:before {
            content: '';
            display: block;
            width: 0.35rem;
            height: 1rem;
            margin-top: -1px;
            margin-right: 1rem;
            background: url(/images/controls/chevron-right-dark.svg) no-repeat
              center;
            background-size: 100% auto;
          }
        }

        a {
          color: currentColor;
          text-decoration: none;
          @include long-text-ellipsis;
          max-width: 200px;
        }
      }
    }
  }

  .admin-dropdown {
    cursor: pointer;
    margin-left: 2rem;
    position: relative;

    .avatar-wrap .avatar {
      background-color: $secondary-color;
    }

    .admin-trigger {
      font-size: 16px;
      cursor: pointer;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      background-color: transparent;
      background: url(/images/controls/chevron-down-dark.svg) no-repeat right
        center;
      background-size: 0.75rem 0.75rem;
      width: 4rem;
      user-select: none;
    }

    .dropdown-component {
      right: 0;
      min-width: 200px;
    }
  }
}
