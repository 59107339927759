.button-reset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-family: $font-family;
  line-height: 1;
  background-color: transparent;
}

.btn-text {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: $button-font-size;
  color: $secondary-color;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  // To remedy text shift during transform animation
  transform: perspective(1px) translateZ(0);

  &:hover {
    color: $primary-color;
  }

  .icon {
    margin-left: 0.5rem;
    width: 18px;
  }

  &:active {
    opacity: 0.9;
    transform: translate(0, 0.125rem);
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    color: $dark-grey;

    &:hover {
      color: $dark-grey;
      cursor: default;
    }
  }
}

.btn {
  @extend .button-reset;
  padding: 0.7rem 1.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  border: solid 1px $primary-color;
  border-radius: $border-radius;
  color: $white;
  font-size: $button-font-size;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  position: relative;
  height: 56px;
  // To remedy text shift during transform animation
  transform: perspective(1px) translateZ(0);
  backface-visibility: hidden;
  will-change: auto;
  white-space: nowrap;

  .icon-wrap {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;

    svg.icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      stroke: currentColor;
      fill: transparent;
    }
  }

  .icon-wrap + span:not(icon-wrap),
  span:not(icon-wrap) + .icon-wrap {
    margin-left: 0.6rem;
  }

  &.min-width {
    min-width: 7.75rem;
  }

  &:hover {
    background-color: lighten($primary-color, 10%);
    border-color: lighten($primary-color, 10%);
    color: $white;
    text-decoration: none;
  }

  &:active {
    opacity: 0.9;
    transform: translate(0, 0.125rem);
  }

  &.btn-small {
    padding: 0.5rem 1.1rem;
    font-weight: 500;
    font-size: 0.735rem;

    .icon-wrap {
      svg.icon {
        width: 1rem;
        height: 1rem;
      }
    }
  }

  &.btn-wide {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  &.btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      background-color: lighten($secondary-color, 10%);
      border-color: lighten($secondary-color, 10%);
    }
  }

  &.btn-outline-primary {
    border-color: $primary-color;
    color: $primary-color;

    background-color: transparent;

    &:hover {
      background-color: transparent;
      border-color: lighten($primary-color, 10%);
      color: lighten($primary-color, 10%);
    }

    &.disabled,
    &:disabled {
      border-color: $grey;
      background-color: transparent;
      color: $dark-grey;

      &:hover {
        border-color: $grey;
        background-color: transparent;
        color: $dark-grey;
        cursor: default;
      }
    }
  }

  &.btn-outline-secondary {
    border-color: $secondary-color;
    color: $secondary-color;

    background-color: transparent;

    &:hover {
      background-color: transparent;
      border-color: lighten($secondary-color, 10%);
      color: lighten($secondary-color, 10%);
    }

    &.disabled,
    &:disabled {
      border-color: $grey;
      background-color: transparent;
      color: $dark-grey;

      &:hover {
        border-color: $grey;
        background-color: transparent;
        color: $dark-grey;
        cursor: default;
      }
    }
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    border-color: $light-grey;
    background-color: $light-grey;
    color: $dark-grey;

    &:hover {
      border-color: $light-grey;
      background-color: $light-grey;
      color: $dark-grey;
      cursor: default;
    }
  }

  &.btn-text {
    background-color: transparent;
    border-color: transparent;
    color: $text-color;

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &.btn-center {
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  &.btn-fullwidth {
    width: 100%;
  }

  &.error {
    color: $error;
  }

  span.number-wrap {
    display: inline-block;
    position: relative;
    margin-left: 0.25rem;
    width: 0.8rem;
    height: 0.8rem;

    span {
      position: absolute;
      top: calc(50% + 1px);
      left: 50%;
      transform: translate(-50%, -50%);
      background: $primary-color;
      padding: 4px 5px;
      border-radius: 0.125rem;
      color: $white;
      font-size: 0.8rem;
      font-weight: 600;
      text-align: center;
    }
  }

  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon-wrap {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      position: relative;

      svg.icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        stroke: currentColor;
        fill: transparent;
      }
    }

    .icon-wrap + .text,
    .text + .icon-wrap {
      margin-left: 0.55rem;
    }
  }
}

.button-group {
  display: flex;

  .btn + .btn {
    margin-left: 1rem;
  }
}
