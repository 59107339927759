.radio-component-button {
  color: $text-color;
  width: 6.25em;
  height: 5.5em;

  @media #{$phone} {
    width: 5.5em;
    height: 4.75em;
  }

  &.isSmall {
    width: 3em;
    height: 3em;
  }

  &.isTab {
    height: 2.6em;
    width: unset;

    &.has-extra-text {
      height: 4em;
    }

    input[type='radio'] + label {
      padding: 0 5rem;
      width: unset;
      display: inline-block;
      border: solid 1px $border-color;
      background-color: $light-grey;
    }

    .content {
      .extra-text {
        display: block;
        font-weight: bold;
        font-size: 1em;
      }
      .text {
        letter-spacing: unset;
        font-size: 0.88em;
        font-weight: 500;

        @media #{$phone-tablet} {
          font-size: 0.7rem;
        }
      }
    }
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
  }

  input[type='radio'] + label {
    position: relative;
    cursor: pointer;
    margin: 0;
    display: block;
    border-radius: $border-radius;
    transition: all 0.25s;
    background-color: $light-grey;
    width: 100%;
    height: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    left: 0;

    .text {
      text-align: center;
      margin: auto;
      display: block;
      letter-spacing: 0.2rem;
    }

    img {
      width: 75%;
      height: auto;
      margin: auto;
    }
  }

  input[type='radio']:checked + label {
    background-color: $secondary-color;
    color: $white;
  }

  &:hover {
    input[type='radio'] + label {
      background-color: $light-grey;
      color: $black;
    }

    input[type='radio']:checked + label {
      background-color: $secondary-color;
      color: $white;
    }
  }

  input[type='radio']:not(:checked) + label::after {
    opacity: 0;
  }

  input[type='radio']:checked + label::after {
    opacity: 1;
  }
}

.radio-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 53px;

  .radio-component-button {
    input[type='radio'] + label {
      padding: 0;
      width: 100%;
      border-radius: 0;
      border: none;
    }

    & + .radio-component-button {
      border-left: 0;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
.two-factor-auth {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 2rem;
  }

  .radio-group {
    width: 100%;
  }

  .btn-outline {
    margin-bottom: 4rem;
  }

  .inline-notification {
    margin-bottom: 2rem;
    max-width: 600px;
  }
}

.access-btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

/* Radio button styling on new MAB-III test type
(not conventional single-select multiple choice)
=================================================== */
.radio-question.updated-radio {
  display: flex;
  flex-flow: column nowrap;
  background: $light-grey;
  padding: 12px 7px;
  border-radius: $border-radius;
  transition: background 0.2s ease;
  cursor: pointer;

  &.hide-radio {
    margin: 0 6px;
  }

  &:hover {
    background: $grey;
  }

  &.is-selected {
    background: $secondary-color;

    .radio-letter {
      color: $white;
      transition: color 0.2s ease;
    }
  }

  &:hover,
  &.is-selected {
    transition: background 0.2s ease;
  }

  &.is-selected:hover input[type='radio']:checked + label {
    color: $black;
  }

  .radio-letter {
    margin: 0 auto 12px;
  }

  input[type='radio'] + label,
  input[type='radio']:checked + label,
  &:hover input[type='radio'] + label,
  input[type='checkbox'] + label,
  input[type='checkbox']:checked + label,
  &:hover input[type='checkbox'] + label {
    background: $white !important;
    opacity: 1;
  }

  img {
    background: $white;
    border-radius: $border-radius;
  }
}
