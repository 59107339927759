@import '/styles/variables.scss';

.verificationLabel {
  font-size: 14px;
  color: $dark-navy;;
}

.verificationValue{
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 500;
  color: $dark-navy;;
}