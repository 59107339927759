.respondent-batch-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .test-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;
    border: 1px solid $grey;

    @media #{$phone} {
      flex-wrap: wrap;
    }

    &.reentry {
      background-color: $mid-blue;
    }

    .test-action {
      margin: auto 2rem;
      display: flex;
      justify-content: center;

      .btn {
        width: 100%;
        min-width: 150px;
      }

      .completed {
        color: $success;
      }

      .icon-wrap {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.35rem;

        svg.icon {
          stroke: $success;
          fill: $white;
          width: 100%;
          height: 100%;
        }
      }
    }

    .test-info {
      margin: 2em;

      p {
        margin: 0;
      }

      h3 {
        margin: 0;
        margin-bottom: 0.25em;
      }
    }
  }
}
