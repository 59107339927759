.popup-card-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 350px;
  width: 500px;
  padding: 2rem !important;
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  animation-name: modalOpen;
  animation-duration: 0.5s;

  .popup-text {
    color: $primary-color;
  }

  .popup-actions {
    display: flex;
    justify-content: center;
    width: 100%;
    justify-content: space-around;

    &.justify-end {
      justify-content: flex-end;

      .btn + .btn {
        margin-left: 1rem;
      }
    }
  }

  .popup-btn {
    padding: 0.7rem 1.75rem;
  }
}
