.select-wrap {
  margin-bottom: $gutterE * 1.5;

  &.small-margin {
    margin-bottom: 0.375rem;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.has-errors {
    select {
      border: 1px solid $error;
      border-radius: $border-radius;
    }
  }

  label {
    margin: 0 0 0.3em 0;
    display: block;
    color: fade($text-color, 90%);
    font-size: 0.875em;
    letter-spacing: 0.05em;
    font-weight: 500;
  }

  & + .select-wrap {
    margin-left: 1rem;
  }
}

.select {
  position: relative;
  overflow: hidden;
  background: $light-grey url(/images/controls/arrow-down.svg) no-repeat
    calc(100% - 1em) center;
  background-size: 0.8em 0.5em;
  border-radius: $border-radius;
  width: 100%;
}

.select select {
  background: transparent;
  width: 100%;
  border: 0;
  -webkit-appearance: none;
  outline: 0;
  appearance: none;
  -moz-appearance: none;
  font-size: 1em;
  font-weight: normal;
  font-family: $font-family;
  color: $text-color;
  padding: 0.75em;
  padding-right: 2.5em;

  option {
    font-family: sans-serif;
  }
}

.select select::-ms-expand {
  display: none;
}

.select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.radio-component,
.checkbox-component {
  & + .select-wrap {
    margin-top: $gutterE * 2.2;
  }
}
