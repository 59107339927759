.radio-component-block {
  margin-bottom: $gutterR / 1.5;
  color: $text-color;
  border: solid 1px $grey;
  margin: 2px;
  width: 100%;
  height: 100%;
  border: 1px solid #979797;
  align-items: center;
  padding: 0.7em 1em;
  padding-bottom: 0.2em;
  display: flex;

  input[type='radio'] {
    position: relative;
    right: 2px;
    transform: scale(2);
  }

  input[type='radio'] + label {
    position: relative;
    padding-left: $check-size * 1.5;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: unset;
    margin: 0;
    font-size: $check-size / 1.6;
    display: flex;
    border-radius: $border-radius;
    transition: all 0.25s;
  }

  &.has-hint {
    input[type='radio'] + label {
      background-color: $white;
      border: solid 1px $grey;
    }
  }

  input[type='radio']:checked + label {
    background-color: $secondary-color;
    color: $white;
  }

  input[type='radio']:checked + label + .hint {
    display: flex;
  }

  &:hover {
    input[type='radio'] + label {
      background-color: fade($secondary-color, 10%);
    }

    input[type='radio']:checked + label {
      background-color: $secondary-color;
    }
  }

  input[type='radio'] + label::before,
  [type='radio'] + label::after {
    content: '';
    position: absolute;
    left: $check-size / 2;
    top: 0.6rem;
    border: 1px solid $grey;
    width: $check-size;
    height: $check-size;
    border-radius: 50%;
    color: $white;
    display: block;
    background-color: $grey;
    transition: all 0.25s;
  }

  [type='radio'] + label::after {
    // background-image is URL encoded SVG definition of images/controls/check-blue.svg
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 13' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EPath%3C/title%3E%3Cpath d='M5.775 9.616l-4.13-3.483-1.29 1.53 5.6 4.721 9.783-10.71L14.262.326z' fill='%23248FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 65% 65%;
    background-color: #f9f9fb;
    transition: all 0.25s;
    background-position: center center;
  }

  input[type='radio']:not(:checked) + label::after {
    opacity: 0;
  }

  input[type='radio']:checked + label::after {
    opacity: 1;
  }

  .radio-component-content {
    padding-right: 1rem;
  }
}

.radio-block-group-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
