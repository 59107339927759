.main-app-container {
  height: 100%;
  min-height: 100vh;
}
ul.demo-nav {
  margin: 0;
  ul {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
  li {
    margin: 0 0 0.5rem 0;
    list-style: none;
  }
}
a {
  color: $secondary-color;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.demo-page {
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
  padding: 40px 0;
  .is-med {
    margin-right: auto;
    margin-left: auto;
  }
}
.icon-grid {
  display: flex;
  gap: 2em;
  flex-wrap: wrap;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 8rem;
    text-align: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 0.5rem;
  }
  svg.icon {
    display: inline-block;
    width: 3em;
    height: 3em;
    stroke: currentColor;
    fill: transparent;
    margin-top: 1rem;
  }
}
.demo-row {
  display: flex;
  margin-bottom: 3em;
  div + div {
    margin-left: 1em;
  }
}
.main-app-container {
  flex: 1;
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media #{$phone-tablet} {
  .mobile-hide {
    display: none;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  max-width: $container-width;
  width: 94%;
  position: relative;
  .dot-flashing {
    margin: 0 auto;
  }
}
.level {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
  width: 100%;

  &.new-team-members-level {
    padding-bottom: 1.5em;
    h3 {
      margin-bottom: 0;
    }
  }
}
.level.no-padding {
  padding: 0;
}
.level.no-padding-bottom {
  padding-bottom: 0;
}
.level:not(.no-margin) > *:not(.level-right) {
  margin-right: var(--gutter);
  &:last-child {
    margin-right: 0;
  }
}
.flex-1 {
  flex: 1;
}
.indented {
  margin-right: 6rem;
  margin-left: 6rem;
}
.center {
  text-align: center;
}
.is-wide {
  max-width: 980px;
}
.is-med-wide {
  width: 100%;
  max-width: $container-width-small;
}
.is-med {
  max-width: 504px;
}
.is-slim {
  max-width: 340px;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}
.no-show {
  display: none;
}
.full-width {
  width: 100%;
}
// Flex helpers
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-between {
  justify-content: space-between;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.no-text-wrap {
  text-wrap: nowrap;
}
$max: 20;
$offset: 1;
$unit: 'em';
// // margin-top helper class
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }
    $i: $i + $offset;
  }
}
@include list-loop('.mt-', 'margin-top');
@include list-loop('.mb-', 'margin-bottom');
@include list-loop('.mr-', 'margin-right');
@include list-loop('.ml-', 'margin-left');
@include list-loop('.pt-', 'padding-top');
@include list-loop('.pb-', 'padding-bottom');
@include list-loop('.pl-', 'padding-left');
@include list-loop('.pr-', 'padding-right');

.flex-center {
  display: flex;
  justify-content: center;
}

.header-top-margin {
  margin-top: 4.5em;
}

@media (max-width: #{$tablet-max-width}), (max-height: 900px) {
  .hide-small-screen {
    display: none;
  }
}

// Disable text selection during test
.layout-active-test {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gap-8 {
  gap: 8px;
}