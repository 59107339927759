@import '/styles/variables.scss';

.heading {
  text-align: center;
  font-size: 1.3rem;
}

.content {
  h1 {
    text-align: center;
  }
}

.footer {
  font-weight: 600;
  font-size: 1.3rem;

  p {
    margin-bottom: 0;
  }
}
