.actionable-item {
  display: grid;
  grid-template-columns: 120px auto;
  margin-bottom: 2rem;
  margin-left: 3rem;

  .added.btn {
    background-color: $success;
    border-color: $success;

    .icon-wrap {
      margin-left: 5px;
    }
  }
}
.actionable-item-content {
  .item-title {
    font-weight: 600;
    font-size: 1.125rem;
    display: block;
    margin-bottom: 1rem;
  }
}
