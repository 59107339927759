.see-more-panel {
  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  &.open {
    max-height: 9999px;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  }
}
