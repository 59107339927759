.card-header {
  .status-bar {
    background-color: $mid-blue;
    padding: 0.75em 2em;

    p {
      font-size: 14px;
      color: $dark-navy;
      margin: 0;

      @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
        font-size: 13px;
        text-align: center;
      }
    }

    &.is-practice {
      background-color: $secondary-color;

      p {
        color: $white;
        text-align: center;
      }

      .small-screen {
        display: none;
      }

      @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
        .small-screen {
          display: block;
        }
      }

      @media (max-width: 640px) {
        left: unset;
        right: 1rem;
        transform: unset;
      }
    }

    @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0.5em 1.5em;
      border-radius: $border-radius;
    }
  }

  .container {
    position: relative;
    height: 8.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;

    @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
      font-size: 80%;
      height: 3.5em;
      margin-bottom: 1.35em;
    }
  }
}
