.select-wrap {
  margin-bottom: $gutterE * 1.5;
  max-width: unset;

  label {
    margin-bottom: 0.3em;
    display: block;
    color: $text-color;
    font-size: 0.8em;
    letter-spacing: 0.05em;
  }
}

.select {
  position: relative;
  overflow: hidden;
  background: $white url(/images/controls/arrow-down.svg) no-repeat center;
  background-size: 0.65em 0.35em;
  border: solid 1px transparent;
  border-radius: 0;
  width: 100%;

}

.select select {
  background: transparent;
  width: 100%;
  border: 0;
  -webkit-appearance: none;
  outline: 0;
  appearance: none;
  -moz-appearance: none;
  font-size: 0.9em;
  font-weight: normal;
  font-family: $font-family;
  color: $text-color;
  padding: 0.8em;
  padding-right: 2.5em;
  
  &[readonly] {
    background-color: $light-grey;
    color: $dark-grey;
  }

  @media #{$phone} {
    font-size: 16px;
  }
}

.multi-select-display {
  position: relative;
  font-size: 0.9rem;
  padding: 0.5rem 0;
}

.multi-select-options {
  position: relative;
  display: inline-block;
  background: $brand-color-4;
  margin: 0.5rem 0.5rem 0 0;
  border-radius: 12px;
  padding: 0.1rem 2rem 0.1rem 0.6rem;
  
  .multi-select-remove {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 10px;
    height: 10px;
    border-width: 0;
    outline: none;
    -webkit-appreance: none;
    background: url('/images/controls/close.svg') no-repeat;
    background-size: 100%;
    cursor: pointer;
    opacity: 0.8;
  }
}

.select select::-ms-expand {
  display: none;
}

.select select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.radio-component,
.checkbox-component {
  & + .select-wrap {
    margin-top: $gutterE * 2.2;
  }
}
