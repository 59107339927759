.large-radios {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .radio-component-button.isTab {
    width: 100%;
    border: solid 1px $border-color;
    overflow: hidden;
    height: 3.2em;
    overflow: hidden;

    @media #{$phone} {
      height: 2.5em;
    }

    input[type='radio']:checked + label {
      background-color: $light-green;
      color: $text-color;
      border: solid 1px $primary-color;
    }

    label {
      padding: 0;
      width: 100%;
      border-radius: 0;
      border: none;
    }

    .content {
      .text {
        letter-spacing: unset;
        font-size: 1rem;
        font-weight: 500;

        @media #{$phone} {
          font-size: 0.75rem;
        }
      }
    }

    & + .radio-component-button {
      border-left: 0;
    }

    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      label {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;

      label {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

.radio-component-button {
  color: $text-color;
  width: 6.25em;
  height: 5.5em;

  &.isTab {
    height: 2.6em;
    width: unset;

    label {
      padding: 0 5rem;
      width: unset;
      display: inline-block;
      border: solid 1px $border-color;
      background-color: $white;
    }

    .content {
      .text {
        letter-spacing: unset;
        font-size: 0.75rem;
        font-weight: 500;
      }
    }
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
  }

  label {
    position: relative;
    cursor: pointer;
    margin: 0;
    display: block;
    border-radius: $border-radius;
    transition: all 0.25s;
    background-color: $light-grey;
    width: 100%;
    height: 100%;
  }

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    left: 0;

    .text {
      text-align: center;
      margin: auto;
      display: block;
      letter-spacing: 0.2rem;
    }

    img {
      width: 75%;
      height: auto;
      margin: auto;
    }
  }

  input[type='radio']:checked + label {
    background-color: $primary-color;
    color: $white;
  }

  &:hover {
    input[type='radio'] + label {
      background-color: fade($primary-color, 5%);
    }

    input[type='radio']:checked + label {
      background-color: $primary-color;
    }
  }

  input[type='radio']:not(:checked) + label::after {
    opacity: 0;
  }

  input[type='radio']:checked + label::after {
    opacity: 1;
  }
}