*,
*:before,
*:after {
  box-sizing: inherit;
}

& {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  scroll-behavior: smooth;
  box-sizing: border-box;

  @media #{$phone} {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
}