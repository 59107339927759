$header-height: 130px;

.admin-wrapper {
  display: flex;
  min-height: inherit;
  background-color: #fff;
  min-width: 1200px;

  .admin-content-wrapper {
    flex: 1;
  }

  .result-count {
    font-size: 1rem;
  }

  .admin-content {
    padding: 2.75rem 2.75rem 0;
    max-width: $container-width;

    &.my-profile {
      max-width: inherit;
      display: flex;
    }

    .section {
      margin-bottom: 4rem;

      h3.underline {
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
      }

      .project-count {
        margin-left: 1em;
        font-size: 0.75em;
        font-weight: 400;
        color: $text-color-alt;
      }
    }

    .header-actions {
      border-bottom: solid 1px $grey;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.no-border {
        border: none;
        margin-bottom: 3rem;
        padding-bottom: 0rem;
      }

      &.btn-only {
        justify-content: flex-end;
      }

      &.no-border {
        border-bottom: none;
      }

      h3 {
        margin: 0;

        span {
          color: $text-color-alt;
          font-weight: 300;
        }
      }

      p {
        margin-bottom: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        color: $text-color-alt;
        font-weight: 300;
      }

      .multi-text {
        display: flex;
        align-items: baseline;
      }

      .actions {
        display: flex;
        align-items: center;

        .btn + .btn {
          margin-left: 1.2em;
        }

        .btn + .btn.no-margin {
          margin-left: 0;
        }
      }

      .statuses {
        display: flex;

        .status-group {
          display: flex;
          margin-right: 2rem;
          align-items: center;
        }

        .status-text {
          color: $primary-color;
          font-size: 0.9rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .table-aside-header {
    p {
      font-size: 0.75em;
      color: $text-color-alt;
      margin-bottom: 0;
    }
  }
}

.centered-header-actions {
  position: relative;
  display: flex;
  justify-content: center;

  .header.with-subtext {
    display: flex;
    flex-direction: column;
  }

  .actions {
    position: absolute;
    right: 0;
  }

  @media screen and (max-width: 1180px) {
    max-width: 90%;
  }
}

.subtitle {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.static-data {
  .label {
    font-weight: 600;
  }

  .value {
    font-weight: 300;
    margin-left: 1.2rem;
    color: $text-color-alt;
  }

  .short {
    width: 8rem;
  }

  .long {
    width: 28rem;
  }

  .btn {
    margin-left: 2.5rem;
  }
}

.batch-data {
  margin: 0;
  padding: 0;
  max-width: 440px;

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 1em;

    .label {
      font-weight: 600;
    }

    .value {
      font-weight: 300;
      margin-left: 1.2rem;
      color: $text-color-alt;
    }

    a {
      color: $text-color;
    }

    .tag {
      margin-left: 1em;
    }

    & + li {
      margin-top: 1.35em;
    }
  }
}

.edit-batch {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 1240px) {
    flex-direction: column;
  }

  .batch-content {
    flex: 1;
    max-width: $container-width-small;
  }

  .batch-legend {
    border: solid 1px $grey;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    order: 2;
    padding: 1.5rem;
    position: sticky;
    top: 3rem;
    z-index: 1;
    background-color: $white;
    display: none;

    @media screen and (max-width: 1300px) {
      padding: 1.2rem;
    }

    @media screen and (max-width: 1240px) {
      width: 100%;
      order: unset;
      margin-bottom: 2rem;
      padding: 1rem;
    }

    ul {
      margin: 0;
      border-left: solid 1px $grey;

      @media screen and (max-width: 1240px) {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        border: none;
        gap: 0.75rem;
      }

      li {
        list-style: none;
        margin: 0;
        font-size: 0.875rem;
        line-height: 1;
        display: flex;

        @media screen and (max-width: 1300px) {
          font-size: 13px;
        }

        & + li {
          margin-top: 1.25rem;
        }

        @media screen and (max-width: 1240px) {
          & + li {
            margin-top: 0;
          }
        }

        span {
          display: flex;
          width: 0.86rem;
          height: 0.86rem;
          border: solid 1px $grey;
          border-radius: 50%;
          background-color: $white;
          margin-left: -0.43rem;
          margin-right: 0.7rem;
          position: relative;

          @media screen and (max-width: 1240px) {
            margin-left: 0;
            margin-right: 0.3rem;
          }
        }

        &.complete {
          color: $secondary-color;
          font-weight: 700;

          span {
            border-color: $secondary-color;

            &::after {
              content: '';
              display: block;
              background-color: $secondary-color;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

.current-batch {
  border: solid 1px $grey;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: 3rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
    font-size: 0.875rem;
  }
}

.message-variables-wrap {
  background-color: $light-grey;
  border-radius: $border-radius;
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-between;

  ul {
    margin: 0;

    li {
      list-style: none;
      font-size: 0.875rem;
      margin: 0 0 0.3rem;

      &:first-child {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }
    }
  }
}

.resource-wrap {
  display: flex;
}

.resource-aside {
  width: 25%;
  margin-right: 2rem;
  min-width: 200px;

  .accordion-actions {
    border-top: solid 1px $grey;
    padding: 1rem;

    button {
      @extend .button-reset;
      display: flex;
      align-items: center;
      font-size: 0.8125rem;

      & + button {
        margin-top: 0.75rem;
      }
    }

    .icon-wrap {
      display: block;
      width: 1rem;
      height: 1rem;
      margin-right: 0.35rem;

      svg.icon {
        display: block;
        width: 100%;
        height: 100%;
        stroke: currentColor;
        fill: transparent;
      }
    }
  }

  .resource-box {
    border-radius: $border-radius;
    border: solid 1px $grey;
    box-shadow: $box-shadow;
    margin-bottom: 1.5rem;
    overflow: hidden;

    .accordion-content {
      .checkbox-component,
      .select-wrap {
        margin: 0;

        & + .checkbox-component,
        & + .select-wrap {
          margin-top: 0.75rem;
        }
      }
    }
  }
}

.resource-content {
  flex: 1;
}

.respondent-info {
  margin-left: 0;
  font-size: 1.1rem;

  li {
    list-style: none;

    & + li {
      margin-top: 0.5rem;
    }
  }
}

.support-info {
  margin-left: 0;
  font-size: 1rem;

  li {
    list-style: none;
    display: grid;
    grid-template-columns: 0.35fr 1fr;
    grid-gap: 1rem;
    margin: 0;
    padding: 0.25rem 0 0.5rem;
    border-bottom: solid 1px $grey;

    & + li {
      margin-top: 0.7rem;
    }
  }
}

.billing {
  padding: 2.75rem 2.75rem 0;

  section {
    margin-bottom: 4rem;
  }

  .billing-content {
    max-width: $container-width-small;
  }

  .filter-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .billing-note {
    font-weight: 500;
    margin-top: 3rem;
  }
}

.usage-heading-row {
  position: relative;

  .group-actions {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.crypto-key-wrapper {
  background: $light-grey;
  padding: 2rem;
  border-radius: 4px;
  border: 1px solid $grey;

  p {
    font-size: 14px;
    font-family: monospace;
    font-weight: 500;
    word-wrap: break-word;
  }
}

.crypto-btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 1rem;
  margin-bottom: 2rem;
  button {
    margin-left: 1rem;
  }
}

.instructions {
  h4 {
    font-size: 1rem;
    font-weight: 600;
  }

  ol {
    margin-bottom: 2rem;
  }
}

.test-filters {
  margin-bottom: 2rem;
  border-bottom: 1px solid $grey;
  border-top: 1px solid $grey;
  padding-top: 1rem;
}
ul.profile-details {
  margin: 0;
}

.profile-details li {
  list-style: none;
  margin-bottom: 1.4em;
}

.profile-details {
  label {
    margin-right: 1rem;
  }
  span {
    color: #7a7a7c;
  }

  .btn {
    margin-left: 1rem;
  }
}

.payment {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  .checkout {
    margin-top: 2rem;
    width: 358px;

    p {
      display: flex;
      margin-bottom: 1rem;
      justify-content: space-between;
    }

    .total {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
  }
}
