.archived-project-accordion {
  margin-bottom: 1rem;

  .accordion__item {
    border-radius: $border-radius * 1.5;

    & + .accordion__item {
      margin-top: 0.9rem;
    }
  }

  .accordion__button {
    background-color: $light-grey;
    border-radius: $border-radius * 1.5;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    outline: 0;
    padding: 0.5rem 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px $border-color;

    .heading {
      font-size: 1rem;
      font-weight: 500;
      color: $text-color;
    }

    .actions {
      display: flex;
      align-items: center;

      .action {
        display: block;
        a + a {
          margin-left: 1rem;
        }
      }

      .icon {
        width: 28px;
        height: 28px;
        border: solid 1px #8894a2;
        border-radius: 50%;
        background-image: url(/images/controls/chevron-down-dark.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 13px 7px;
        margin-left: 2rem;
      }
    }
  }

  .accordion__button[aria-expanded='true'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .actions {
      .icon {
        background-image: url(/images/controls/chevron-up-dark.svg);
      }
    }
  }

  .accordion__panel {
    animation: accordionFadeIn 0.35s ease-in;
    border: solid 1px $border-color;
    border-top: 0;
    border-bottom-right-radius: $border-radius * 1.5;
    border-bottom-left-radius: $border-radius * 1.5;
    background-color: $light-grey;
    color: transparentize($text-color, 0.1);

    .empty-batches {
      padding: 0.9rem 1.5rem;
      font-size: 0.9rem;
    }

    ul.batch-list {
      margin: 0;

      li {
        list-style: none;
        font-size: 0.9rem;
        padding: 0.9rem 1.5rem;

        margin: 0;
        border-bottom: solid 1px $border-color;

        &:last-child {
          border: none;
        }
      }
    }
  }
}
