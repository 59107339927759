.accordion-project {
  margin-top: 3em;
  .accordion__item + .accordion__item {
    margin-top: 1em;
  }

  .accordion__item {
    border-radius: $border-radius * 1.5;
  }

  .accordion__button {
    background-color: $light-grey;
    border-radius: $border-radius * 1.5;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    outline: 0;
    padding: 1.25em 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px $border-color;
    min-height: 75px;

    .heading {
      font-size: 1rem;
      font-weight: 600;
      color: $text-color;
      max-width: 800px;
      @include long-text-ellipsis;

      @media screen and (max-width: 1325px) {
        max-width: calc(100vw * 0.45);
      }
      @media screen and (max-width: 1215px) {
        max-width: 600px;
      }
      @media screen and (max-width: 835px) {
        max-width: 350px;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .action {
        display: none;
        a + a {
          margin-left: 1rem;
        }
      }

      .icon {
        width: 28px;
        height: 28px;
        border: solid 1px #8894a2;
        border-radius: 50%;
        background-image: url(/images/controls/chevron-down-dark.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 13px 7px;
        margin-left: 2rem;
      }
    }
    .team-members-heading {
      font-size: 1em;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.2px;
    }
  }

  .accordion__button[aria-expanded='true'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .heading {
      max-width: 680px;

      @media screen and (max-width: 1345px) {
        max-width: calc(100vw * 0.3);
      }

      @media screen and (max-width: 830px) {
        max-width: 237px;
      }
    }

    .actions {
      .action {
        display: block;
      }
      .icon {
        background-image: url(/images/controls/chevron-up-dark.svg);
      }
    }
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: accordionFadeIn 0.35s ease-in;

    border: solid 1px $border-color;
    border-top: 0;
    border-bottom-right-radius: $border-radius * 1.5;
    border-bottom-left-radius: $border-radius * 1.5;

    .empty-batches {
      padding: 1.25em 1.5em;
    }

    .new-batch {
      padding: 1.25em 1.5em;
      border-top: solid 1px $border-color;
      text-decoration: none;
      font-weight: 500;
      color: $dark-navy;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
        background-color: $light-blue;
      }

      .icon-wrap {
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 5px;
      }
    }

    ul.batch-list {
      margin: 0;
      padding: 0;

      .title {
        @include long-text-ellipsis;
        max-width: calc(100vw * 0.5);
      }

      li {
        list-style: none;
        padding: 0;
        margin: 0;
        border-bottom: solid 1px $border-color;

        .title {
          font-size: 1rem;
          background: url(/images/icons/circle-arrow-right.svg) no-repeat right
            center;
          background-size: 16px 14px;
          padding-right: 30px;
        }

        &:last-child {
          border: none;
        }

        a {
          padding: 1.25em 1.5em;
          display: block;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          &:hover {
            background-color: $light-grey;

            .title {
              text-decoration: underline;
              background-image: url(/images/icons/circle-arrow-right-active.svg);
            }
          }
        }
      }
    }
  }
}
