.hover-info-box {
  position: relative;

  &:hover .text-box {
    visibility: visible;
    opacity: 1;
  }

  .text-box p {
    color: $white !important;
    margin: 0 !important;
  }

  .text-box {
    visibility: hidden;
    opacity: 0;
    font-size: 0.9em;
    white-space: nowrap;
    padding: 0.6em 1.75em;
    border-radius: $border-radius;
    background-color: $light-navy;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: all 0.25s;

    &.show-more {
      left: -75%;

      .arrow-up {
        left: 89%;
      }
    }

    &.right-align {
      left: unset;
      right: 0;
      transform: translate(0, 100%);

      .arrow-up {
        left: 90%;
      }
    }
  }

  &.disabled .text-box {
    background-color: $text-color-alt;
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $primary-color;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -98%);
  }

  &.disabled .arrow-up {
    border-bottom: 6px solid $text-color-alt;
  }
}
