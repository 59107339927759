.logo-wrap {
  width: 15.625em;
  height: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  &.respondent {
    @media (max-width: 1120px) {
      width: 12em;
    }

    @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
      padding-left: 0;
      position: absolute;
      top: 13px;
      width: 11em;
      height: auto;
    }

    @media (max-width: 640px) {
      width: 10em;
    }
  }
}
