.accordion-list {
  .accordion__heading {
    border-top: 1px solid $grey;

    .accordion__button {
      cursor: pointer;
      padding: $gutterR 0 $gutterR 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading-wrapper {
        display: flex;
        align-items: center;
      }

      .heading {
        font-weight: 600;
        color: $text-color;

        &.alt {
          font-weight: 400;
          color: $text-color-alt;
        }
      }

      .heading-icon {
        .icon-wrap {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .no-items {
    color: $text-color;
    text-align: center;
    margin: 2rem auto;
  }

  .accordion__item {
    &:last-of-type {
      border-bottom: 1px solid $grey;
    }
  }

  .accordion__panel {
    animation: accordionFadeIn 0.35s ease-in;

    padding: $gutterR 0 $gutter * 2 0;
    .actions {
      display: flex;
      justify-content: flex-end;
      padding-bottom: $gutterR * 2;
      button,
      a {
        margin-left: 1em;
      }
    }
  }

  .disabled-hint-text {
    font-style: italic;
    font-size: 0.8rem;
    color: gray;
    margin-top: 0.5rem;
  }
}

.admin-wrapper .admin-content .accordion-list .header-actions {
  margin-bottom: 0;
  border-bottom: 0;
}
