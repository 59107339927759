$aside-gutter: 1.5rem;
$admin-aside-width: 314px;

.aside-heading {
  padding: 0 $aside-gutter;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aside-add-new {
  text-indent: -9999px;
  background: url(/images/controls/circle-plus-dark.svg) no-repeat center center;
  background-size: contain;
  width: 1.25rem;
  height: 1.25rem;
  @extend .button-reset;
  margin-right: 0.3rem;
}

aside.admin-aside {
  background: $light-grey;
  width: $admin-aside-width;
  border-right: solid 1px $grey;
  display: flex;
  flex-direction: column;
  user-select: none;

  &.is-hidden {
    margin-left: -284px;

    @media #{$phone-tablet} {
      margin-left: -190px;
    }

    .logo-wrap {
      visibility: hidden;
    }

    .aside-content {
      opacity: 0;
    }
    .aside-header .toggle {
      background-image: url(/images/controls/double-chevron-right.svg);
    }
  }

  @media #{$phone-tablet} {
    width: 220px;
  }

  .loading-ellipsis {
    width: 100%;
    display: inline-block;
    margin-top: 0.5rem;
  }

  .aside-header {
    height: $header-height - 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .toggle {
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 2rem;
      height: 2rem;
      text-indent: -9999px;
      background: $primary-color url(/images/controls/double-chevron-left.svg)
        no-repeat center center;
      background-size: 72% 72%;
      border-radius: 50%;
      display: block;
      z-index: 10;
      @extend .button-reset;
    }

    .logo-wrap {
      width: 80%;
    }
  }

  .aside-content {
    border-top: solid 1px $grey;
    flex: 1;
    padding: $aside-gutter 0 0rem 0;
  }

  .aux-actions {
    @media screen and (min-height: 600px) {
      position: sticky;
      // top: 85vh;
      top: 90vh;
    }

    ul {
      margin: 0;
    }

    li {
      list-style: none;
      margin: 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1;
    }

    a {
      padding: $aside-gutter / 2 $aside-gutter;
      color: $text-color;
      text-decoration: none;
      display: flex;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 500;

      &.active {
        background-color: $secondary-color;
        color: $white;

        .icon {
          -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
        }
      }
    }

    .icon-wrap {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.5rem;
    }
  }
}
