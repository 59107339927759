.text-box__wrapper {
  .text-box,
  &.text-box {
    background-color: $light-grey;
    border-radius: $border-radius;
  }

  &.sharp {
    border-radius: 0px;
  }

  &.multi-line {
    padding: 1.8rem 2.5rem;

    .line-item {
      display: flex;
    }

    .line-item + .line-item {
      margin-top: 1.5rem;
    }

    .label {
      font-size: 1rem;
      font-weight: 500;
      margin: 0;
      margin-right: 1rem;
    }

    .data {
      color: $text-color-alt;
    }

  }

  .single-line {
    font-weight: 500;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    display: flex;
    justify-content: space-between;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      .icon-wrap {
        width: 1.3rem;
        height: 1.3rem;

        svg.icon {
          stroke: $text-color-alt;
          width: 100%;
          height: 100%;

          &:hover {
            stroke: $text-color;
          }
        }
      }
    }
  }

  .blurb {
    padding: 2.25rem 2.875rem;
    border-radius: 0px;
    color: $text-color;
    display: flex;
    justify-content: space-between;

    .paragraph {
      width: 70%;
      margin-bottom: 0;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      width: 26%;

      &.single-action {
        justify-content: flex-end;
        align-self: center;
      }
    }
    .btn {
      padding: 0.5rem 1.25rem;
      height: 45px;
    }
    .btn + .btn {
      margin-left: 1.2em;
    }
  }

  .no-data-display {
    height: 50vh;
    border-radius: 3px;
    border: 1px solid $grey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .heading {
      flex-direction: column;
      color: $primary-color;
      font-size: 1rem;
      display: flex;
      font-weight: 600;
      align-items: center;
      margin-bottom: 1rem;
    }

    .icon-wrap {
      height: 2.4rem;
      width: 2.4rem;
      margin-bottom: 1rem;
      color: $secondary-color;
    }

    .info {
      width: 40%;
      text-align: center;
      margin-bottom: 0;
      font-weight: 300;
    }
  }
}
