.report-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 3em;

  &.disabled {
    color: rgba(122, 122, 124, 0.5);
    border-color: currentColor;
    background-color: transparent;
    cursor: default;

    &:hover {
      color: rgba($text-color-alt, 50%);
      border-color: currentColor;
      background-color: transparent;
    }
  }

  .icon-wrap {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 14px;
  }

  .csv-name {
    margin-right: 1rem;
  }

  .csv-unavailable {
    border: 1.25px solid $error;
    border-radius: 0.25rem;
    padding: 0.25rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: $error;
  }
}
