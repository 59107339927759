.button-reset {
  border: 0;
  outline: 0;
  cursor: pointer;
  font-family: $font-family;
  line-height: 1;
  background-color: transparent;
}

.btn {
  @extend .button-reset;
  padding: 0.65rem 1.1rem;
  display: inline-block;
  background-color: $primary-color;
  border: solid 1px $primary-color;
  border-radius: $border-radius;
  color: $white;
  font-size: 0.95rem;
  font-weight: 300;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  position: relative;
  margin: 0;

  @media #{$small-tablet} {
    padding: 0.5rem 1.1rem;
    font-size: 0.9rem;
  }

  &:hover {
    background-color: darken($primary-color, 6%);
    border-color: darken($primary-color, 6%);
    color: $white;
    text-decoration: none;
  }

  &:active {
    opacity: 0.9;
    transform: translateY(4px);
  }

  &.btn-med-wide {
    padding-right: 2.6rem;
    padding-left: 2.6rem;
  }

  &.btn-wide {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  &.btn-secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;

    &:hover {
      background-color: darken($secondary-color, 6%);
      border-color: darken($secondary-color, 6%);
    }
  }

  &.btn-light {
    background-color: $mid-blue;
    border-color: $mid-blue;
    color: $primary-color;

    &:hover {
      background-color: darken($mid-blue, 6%);
      border-color: darken($mid-blue, 6%);
    }
  }

  &.btn-outline {
    padding: 0.65rem 1.1rem;
    background-color: transparent;
    border-color: currentColor;
    color: $primary-color;

    &:hover {
      background-color: transparent;
      color: $secondary-color;
    }

    &.disabled,
    &:disabled {
      color: rgba($text-color-alt, 50%);
      border-color: currentColor;
      background-color: transparent;

      &:hover {
        color: rgba($text-color-alt, 50%);
        border-color: currentColor;
        background-color: transparent;
      }
    }
  }

  &.disabled,
  &:disabled {
    border-color: $text-color-alt;
    background-color: $text-color-alt;
    cursor: default;
    opacity: 0.5;

    &:hover {
      border-color: $text-color-alt;
      background-color: $text-color-alt;
    }
  }

  &.btn-text {
    background-color: transparent;
    border-color: transparent;
    color: $text-color;
    font-weight: 400;

    &.navy {
      color: $dark-navy;
    }

    &.no-padding {
      padding: 0;
    }

    &.disabled,
    &:disabled {
      color: rgba($text-color-alt, 70%);
    }

    &.disabled-navy {
      color: $dark-navy;
      font-weight: 500;

      .icon-wrap {
        margin: 0 3px;
      }
    }

    &:hover {
      background-color: darken($white, 6%);
      border-color: darken($white, 6%);
    }
  }

  &.btn-prev,
  &.btn-next {
    background-color: $white;
    border-color: $white;
    color: $text-color;
    padding-right: 0;
    padding-left: 0;
    background-repeat: no-repeat;
    background-size: 0.4375rem 0.875rem;

    @media #{$phone} {
      font-size: 0.75rem;
    }
  }

  &.btn-prev {
    &:hover {
      background-color: darken($white, 6%);
      border-color: darken($white, 6%);
    }
    padding-left: 1rem;
    padding-right: 1rem;
    background-position: left center;
  }

  &.btn-next {
    background-image: url(/images/icons/icon-next-dark.svg);
    padding-right: 1rem;
    background-position: right center;
  }

  &.btn-sub {
    @extend .btn-prev;
    font-weight: 400;
    color: $dark-navy;
  }

  &.btn-next-question,
  &.btn-prev-question {
    border: none;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 2em;
    &:hover {
      background-color: transparent;
      color: $secondary-color;
      border-color: $secondary-color;
    }
  }

  &.btn-prev-question {
    background-image: url(/images/icons/icon-back-dark.svg);
    background-position: 15% 50%;
  }

  &.btn-next-question {
    background-image: url(/images/icons/icon-next-dark.svg);
    background-position: 85% 50%;
  }

  &.btn-center {
    margin-right: auto;
    margin-left: auto;
    display: block;
  }

  &.btn-fullwidth {
    width: 100%;
  }

  &.error {
    color: $error;
  }

  &.btn-even-padding {
    padding: 0.625rem;
    height: 2.3rem;
  }

  span.number-wrap {
    display: inline-block;
    position: relative;
    margin-left: 0.25rem;
    width: 0.8rem;
    height: 0.8rem;

    span {
      position: absolute;
      top: calc(50% + 1px);
      left: 50%;
      transform: translate(-50%, -50%);
      background: $primary-color;
      padding: 4px 5px;
      border-radius: 0.125rem;
      color: $white;
      font-size: 0.8rem;
      font-weight: 600;
      text-align: center;
    }
  }

  &.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon-wrap {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      position: relative;

      svg.icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        stroke: currentColor;
        fill: transparent;
      }
    }

    .icon-wrap + .text,
    .text + .icon-wrap {
      margin-left: 0.55rem;
    }
  }

  &.btn-small {
    padding: 0.45rem 0.95rem;
    font-size: 0.9rem;
    border-radius: $border-radius * 1.75;

    &.btn-icon {
      .icon-wrap {
        margin-right: 8px;
      }

      &.btn-copy {
        padding: 0.45rem;

        .icon-wrap {
          margin-right: 0px;
        }
      }
    }

    .icon-wrap + .text {
      margin-left: 0;
    }
  }
}

.button-group {
  display: flex;

  .btn + .btn {
    margin-left: 1rem;
  }
}

.button-group-tab {
  display: inline-block;
  margin: 0 auto;
  overflow: hidden;
  width: auto;
  border-radius: $border-radius;

  .btn-tab {
    border-radius: 0;
    padding: 0.65rem 3rem;
    color: $text-color-alt;
    background-color: $light-grey;
    font-size: 0.9rem;
    font-weight: 400;
    border: none;

    &.active {
      color: $white;
      background-color: $secondary-color;
    }
  }
}

.btn .new-user-btn {
  height: 44px;
}
.medium-width {
  width: 144px;
}

.text-btns {
  background-color: $light-grey;
  margin-bottom: 3em;
  padding: $gutterE * 2 $gutterE * 3;
  display: flex;
  align-items: center;
  border-radius: $border-radius;

  &.small-mb {
    margin-bottom: 2rem;
  }

  p {
    margin: 0;
    padding: 0 1.2em 0 0;
  }

  .actions {
    display: flex;
    align-items: center;

    .btn + .btn {
      margin-left: 1.2em;
    }

    &.draft {
      @media #{$phone-tablet} {
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
        height: 85px;

        .btn + .btn {
          margin-left: 0;
        }
      }
    }
  }
}

.btn-total {
  margin-left: 0.625em;
  height: 1.25rem;
  border-radius: 0.1875rem;
  padding: 0.3125rem;
  background-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 0.75em;
    font-weight: 500;
    color: $white;
    text-align: center;
  }
}

.show-more-btn {
  border: none;
  width: 2rem;
  height: 2rem;
  background: url(/images/icons/icon-ellipsis-dark.svg) no-repeat center;
  background-size: 85%;
  margin-left: 0.5rem;

  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
}
