.tags-list {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 0 1em -6px;
  width: calc(100% + 6px);

  li {
    list-style: none;
    margin: 0 0 3px 6px;
  }

  @media #{$phone-tablet} {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    margin: 0 0 1em 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      width: auto;
      display: inline-block;
      margin: 0;

      & + li {
        margin-left: 0.5em;
      }
    }
  }
}

.tag {
  background-color: $grey;
  padding: 4px 11px;
  font-size: 11px;
  border-radius: $border-radius * 3;
  color: $black;

  &.regional-charity,
  &.charity {
    background-color: $secondary-color;
    color: $white;
  }

  &.supplier {
    background-color: darken($light-green, 8%);
    color: $secondary-color;
  }

  &.consumer {
    background-color: $light-blue;
    color: $secondary-color;
  }
}
