.descriptive-text {
  background-color: $light-grey;
  padding: 3rem;
  border-radius: $border-radius;

  a {
    color: $secondary-color;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }
}
