@keyframes modalOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalContentOpen {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

.modal-component-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(20, 30, 50, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  animation-name: modalOpen;
  animation-duration: 0.2s;

  &.small {
    .modal-component-content {
      max-width: 415px;
    }
  }

  &.medium {
    .modal-component-content {
      max-width: 660px;

      @media #{$phone} {
        max-width: unset;
      }
    }
  }

  &.medium-large {
    .modal-component-content {
      max-width: 750px;

      @media #{$phone} {
        max-width: unset;
      }
    }
  }

  &.large {
    .modal-component-content {
      max-width: 1160px;

      @media #{$phone} {
        max-width: unset;
      }
    }
  }

  &.overflow {
    .modal-component-content {
      overflow: visible;
    }
  }

  .modal-header {
    padding: 2em;
    background: $light-blue;

    h2 {
      margin: 0;
      font-size: 1.438em;
    }

    &.error {
      background: $error;

      h2 {
        color: $white;
      }
    }
  }

  .modal-component-content {
    overflow: auto;
    padding: 0;
    text-align: left;
    width: 92%;
    max-width: 610px;
    border-radius: $border-radius * 2;
    box-shadow: $box-shadow;
    z-index: 100;
    // To remedy text shift during transform animation
    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;
    will-change: auto;
    outline: 0;
    max-height: 95vh;
    animation-name: modalContentOpen;
    animation-duration: 0.5s;
    // padding: 2.35em 2em;
    background: $white;

    @media #{$phone} {
      width: 94%;
      max-height: 100%;
      border-radius: 0;
      font-size: 81%;
    }

    .close {
      text-indent: -9999px;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      background: url(/images/controls/close.svg) no-repeat center center;
      background-size: 55%;
      cursor: pointer;
      padding: 0;
      opacity: 0.65;
    }

    ol,
    ul {
      margin-left: 1.75rem;
    }
  }

  .modal-content {
    padding: 2rem 3rem;
    width: 100%;
    overflow-y: auto;

    &.wide {
      padding: 0;

      h1 {
        padding: 3rem 3rem 0;
      }
    }

    h1 {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      color: $dark-navy;
    }

    @media #{$phone} {
      padding-right: 2rem;
      padding-left: 2rem;
    }

    .qr-code {
      width: 100%;
      max-width: 155px;
      margin: 0 auto 1.5rem;

      img {
        width: 100%;
        height: auto;
      }
    }

    .modal-warning {
      .icon-wrap {
        color: #f67c47;
      }

      strong {
        color: #ce531e;
      }
    }

    .error {
      color: $error;
    }
  }

  .modal-actions {
    width: 100%;
    padding: 1.25rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f7fb;
    border-bottom-right-radius: $border-radius * 2;
    border-bottom-left-radius: $border-radius * 2;

    &.space-between {
      justify-content: space-between;
    }

    &.sticky-bottom {
      position: sticky;
      bottom: 0;
    }

    button {
      min-width: 110px;
    }
    .btn-outline {
      background-color: $white;
    }

    .btn + .btn {
      margin-left: 1rem;
    }

    &.new-team-member {
      background-color: transparent;
    }

    &.background-inherit {
      background-color: inherit;
    }
  }
  .header-bottom-border {
    margin-bottom: 2em;
  }
}

.modal-scrollable-area {
  max-height: 50vh;
}

.modal-scrollable-area-large {
  max-height: 95vh;
}

.terms-scroll-container {
  max-height: 10rem;
  width: 100%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px 2px $grey;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 1rem;
}

.isLarge .terms-scroll-container {
  max-height: 24rem;
}

.terms-scroll-read {
  height: 0.5rem;
  pointer-events: none;
}

.flash-instructions {
  animation: flash 0.75s linear forwards;
  font-weight: 700;
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.25;
  }
}

.terms-checkbox-row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 0.75rem;
    color: $secondary-color;
  }
}

.new-member-consent-title {
  width: 100%;
  color: $dark-navy;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.7rem;
  font-weight: 800;
}

.new-member-consent-subtitle {
  align-self: center;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 400;
}

.new-member-consent-terms-title {
  margin-top: 1rem;
}

.modal-offline-note {
  strong {
    color: red;
  }
  font-weight: 500;
  text-align: left;
}
