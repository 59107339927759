@import 'styles/variables.scss';

.banner {
  height: 45px;
  display: flex;
  padding: 0.4em;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;

  font-size: 14px;

  .content-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  &.blue,
  &.blue a {
    background-color: $mid-blue;
    color: $text-link-color;
  }

  :global(.icon-wrap) {
    width: 20px;
    height: 20px;
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    border: none;
    cursor: pointer;
    width: 34px;
    height: 34px;
    color: $white;
    margin-left: auto;
    margin-right: 8px;
  }
}
