html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: $white;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  scroll-behavior: smooth;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;

  @media #{$phone} {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  &.light {
    background-color: $white;
  }

  &.modal-open {
    overflow: hidden;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

.notification-bar .close-icon {
  display: none;
}

.notification-bar {
  top: 0;
}

.notification-bar.success {
  background-color: $success !important;
}

.notification-bar .text::before {
  width: 1.5em !important;
  height: 1.5em !important;
  margin-right: 1em !important;
}

.app {
  min-height: 100vh;
  height: fit-content;
}

.print {
  min-height: auto;
  background-color: $white;
  padding-bottom: 0;
}
