.progress-bar {
  &__container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    margin: 0 auto;
    font-size: 0.8em;

    @media (max-width: 950px) {
      width: 400px;
    }

    @media (max-width: 820px) {
      width: 350px;
    }
  }

  &__minimal {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__text {
    text-align: center;
    margin-bottom: 5px;
    color: #7a7a7c;
  }

  &__bar {
    width: 100%;
    align-self: center;
    border-radius: 3px;
    height: 12px;
  }
}
