.batch-status-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .display-section {
    background-color: $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    flex-wrap: wrap;

    span {
      font-size: 0.875em;
    }

    p {
      margin: 0;
    }

    .date {
      min-width: 180px;
    }

    .open {
      color: $success;
    }
    .closed {
      color: $error;
    }
  }
  .statuses-display {
    flex: 1;
    min-width: 330px;

    span {
      line-height: 1.8rem;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    @media screen and (max-width: 1200px) {
      span + span {
        margin-right: 0.5em;
      }
    }
  }

  .secondary-display {
    margin: 0 1em;
    flex-basis: 30%;

    &.btn-display {
      flex-basis: 35%;

      @media screen and (max-width: 1385px) {
        .btn {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }

      @media screen and (max-width: 1275px) {
        .btn {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }
    }

    .copy-room-code-button:disabled {
      color: $white;
      cursor: not-allowed;
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      flex-basis: unset;
      justify-content: space-around;

      button {
        margin-top: 0.5em;
      }

      &.btn-display {
        flex-basis: 22%;
        .btn {
          &:first-of-type {
            margin-top: 0;
            margin-bottom: 0.4rem;
          }
          width: 100%;
          padding: 0.5rem 2rem;
        }
      }
    }

    @media screen and (max-width: 930px) {
      &.btn-display .btn {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
    }
  }

  .dropdown {
    align-self: center;
    position: relative;

    .dropdown-component {
      right: 0;
      min-width: 200px;
    }
  }
}

.batch-code-list {
  // TODO - change font to match figma?
  font-family: 'Courier New', Courier, monospace;
  padding: 1rem 0;
  display: flex;
  flex-flow: row wrap;
  li {
    list-style: none;
    flex-basis: 12.5%;
  }

  @media screen and (max-width: 1280px) {
    li {
      flex-basis: 16.66%;
    }
  }

  @media screen and (max-width: 1120px) {
    li {
      flex-basis: 20%;
    }
  }

  @media screen and (max-width: 900px) {
    li {
      flex-basis: 25%;
    }
  }
}
