.accodion-container {
  margin-left: 11%;
}
.accordion-billing {
  margin-top: 3em;
  .accordion__item + .accordion__item {
    margin-top: 1em;
  }

  .accordion__item {
    border-radius: $border-radius * 1.5;
  }

  .accordion__button {
    background-color: $light-grey;
    border-radius: $border-radius * 1.5;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    outline: 0;
    padding: 1.25em 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: solid 1px $border-color;
    min-height: 75px;

    .heading {
      font-size: 1rem;
      font-weight: 600;
      color: $text-color;
    }

    .sub-heading {
      font-weight: 500;
      font-size: 1em;
      color: #1b324a;
    }

    .actions {
      display: flex;
      align-items: center;

      .action {
        display: none;
        a + a {
          margin-left: 1rem;
        }
      }

      .icon {
        width: 28px;
        height: 28px;
        border: solid 1px #8894a2;
        border-radius: 50%;
        background-image: url(/images/controls/chevron-down-dark.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 13px 7px;
        margin-left: 2rem;
      }
    }
  }

  .accordion__button[aria-expanded='true'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .actions {
      .action {
        display: block;
      }
      .icon {
        background-image: url(/images/controls/chevron-up-dark.svg);
      }
    }
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: accordionFadeIn 0.35s ease-in;
    border: solid 1px $border-color;
    border-top: 0;
    border-bottom-right-radius: $border-radius * 1.5;
    border-bottom-left-radius: $border-radius * 1.5;

    .empty-batches {
      padding: 1.25em 1.5em;
    }

    ul.batch-list {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 0;
        margin: 0;
        border-bottom: solid 1px $border-color;

        .title {
          font-size: 1rem;
          background: url(/images/icons/circle-arrow-right.svg) no-repeat right
            center;
          background-size: 16px 14px;
          padding-right: 30px;
        }

        &:last-child {
          border: none;
        }

        a {
          padding: 1.25em 1.5em;
          display: block;
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-decoration: none;

          &:hover {
            background-color: $light-grey;

            .title {
              text-decoration: underline;
              background-image: url(/images/icons/circle-arrow-right-active.svg);
            }
          }
        }
      }
    }
  }

  .list-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 2em;
  }

  li {
    list-style: none;
    margin: 0;
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1;

    & + li {
      margin-left: 3em;

      @media screen and (max-width: 900px) {
        margin-left: 1rem;
      }
      @media screen and (max-width: 836px) {
        margin-left: 0.5rem;
      }
    }

    @media screen and (max-width: 1245px) {
      font-size: 70%;
    }

    .list-header {
      color: #7a7a7c;
      font-size: 1em;
      font-weight: 600;
    }
    .icon-text-container {
      display: inline-flex;
      align-items: center;
      flex-direction: row;
      margin-top: 10px;

      .icon-wrap {
        width: 1.5em;
        height: 1.5em;
      }
      .text {
        color: #1b324a;
        font-weight: 600;
        font-size: 1.5em;
        margin-left: 10px;
      }
    }
  }
  .links-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: auto;

    .row {
      display: flex;

      @media screen and (max-width: 1245px) {
        font-size: 70%;
      }

      & + .row {
        margin-top: 1em;
      }

      a.link-row {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .text {
          font-weight: 600;
          color: #7a7a7c;
          font-size: 1em;
          margin-right: 0.75em;
        }

        .link-right-arrow {
          width: 1.3em;
          height: 1.3em;
          text-indent: -9999px;
          @extend .button-reset;
          background: url(/images/icons/circle-arrow-right.svg) no-repeat center;
          background-size: contain;
        }

        &:hover {
          .text {
            color: $secondary-color;
          }

          .link-right-arrow {
            background-image: url(/images/icons/circle-arrow-right-active.svg);
          }
        }
      }
    }
  }
}
.admin-content-inner {
  display: flex;
  width: 100%;
}

.usage-reports {
  width: 100%;

  @media screen and (max-width: 930px) {
    .text-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      p {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.back-button-section {
  margin-right: 5%;
  cursor: pointer;
}
.billing-dropdown li {
  display: block;
  text-decoration: none;
  color: #272727;
  background-repeat: no-repeat;
  background-position: 1rem 0.5rem;
  background-size: 1.25rem;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  width: 100%;
  text-align: left;
}
select.billing-select {
  width: 230px;
}
