.back-button {
  font-size: .9em;
  color: $text-color-alt;
  text-decoration: none;
  font-weight: 300;
  &:before {
   content: "<";
   margin: -2px 5px 2px 0;
   color: $text-color-alt;
  }

  &:hover {
    cursor: pointer;
    color: $text-color;

    &:before {
      color: $text-color;
    }
  }
}
.back-button-section {
  margin-right: 20%;
}
