.dashboard-actions {
  display: flex;
  flex-direction: row;
  gap: 1.4rem;
  border-bottom: solid 1px $border-color;
  padding-bottom: 3rem;

  .card-action {
    flex-grow: 1;
    flex-basis: 0;
    border: solid 1px $border-color;
    background-color: $white;
    padding: 0.8rem 1rem;
    border-radius: $border-radius;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:active,
    &:hover {
      opacity: 0.9;
      transform: translateY(3px);
    }

    @media #{$phone-tablet} {
      padding: 0.75rem;
    }

    .content {
      display: flex;
      align-items: center;
    }

    .card-icon {
      width: 2.75rem;
      height: 2.75rem;
      background-color: $mid-blue;
      margin-right: 1.25rem;
      border-radius: 6px;
      display: flex;
      flex-shrink: 0;

      @media #{$phone-tablet} {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      .icon-wrap {
        display: block;
        width: 55%;
        height: 55%;
        margin: auto;
      }
    }

    .text {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.2;

      @media #{$phone-tablet} {
        font-size: 0.75rem;
      }
    }
  }
}
