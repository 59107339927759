.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6rem;

  .btn {
    height: 40px;
  }
}

.hamburger {
  display: none;
  width: 40px;
  height: auto;
  object-fit: scale-down;
}

.nav-menu {
  display: flex;
  align-items: center;
  margin: 0;
}

.nav-item {
  margin-left: 1rem;
  list-style: none;
}

.btn-assessment {
  margin-right: 1rem;
}

.header-logo {
  width: 120px;
  height: auto;
  object-fit: scale-down;

  @include for-tablet-landscape-up {
    width: 180px;
  }
}

@media only screen and (max-width: $tablet-min-width) {
  .nav-menu {
    position: absolute;
    left: -2rem;
    z-index: 99;
    height: 0;
    overflow: hidden;
    opacity: 0;
    top: 6rem;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2rem;
    background-color: $white;
    width: calc( 100% + 4rem);
    margin: 0;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    transition: height 0.2s ease-in, opacity 0.2s ease 0.1s, padding 0.2s ease;

    .btn-assessment {
      margin-right: 0;
    }
  }

  .nav-menu.active {
    height: 40vh;
    opacity: 1;
    padding: 1rem 2rem;
    transition: height 0.2s ease-in, opacity 0.2s ease 0.1s, padding 0.2s ease;
  }

  .nav-item {
    margin: 0.8rem 0;
  }

  .hamburger {
    display: block;
    cursor: pointer;

    &.disabled {
      pointer-events: none;
    }
  }
}
