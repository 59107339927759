$check-size: 1.4rem;

.checkbox-component {
  margin-bottom: $gutterE * 1.1;
  color: $text-color;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 10px;
  margin-right: 10px;

  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.larger {
    input[type='checkbox'] + label {
      font-size: $check-size / 1.4;
    }
  }

  &.strong {
    input[type='checkbox'] + label {
      font-weight: bold;
    }
  }

  &.right-checkbox {
    input[type='checkbox'] + label:before,
    input[type='checkbox'] + label:after {
      left: unset;
      right: 0;
    }
    input[type='checkbox'] + label {
      padding-right: 2rem;
    }
  }

  &.read-only {
    input[type='checkbox'] + label::before,
    input[type='checkbox'] + label::after {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  input[type='checkbox'] + label {
    position: relative;
    cursor: pointer;
    text-transform: none;
    font-weight: normal;
    letter-spacing: unset;
    margin: 0;
    padding-left: $check-size * 1.45;
    font-size: $check-size / 1.6;
    font-weight: normal;
    display: inline-block;
    justify-content: left;
  }

  &:hover {
    input[type='checkbox'] + label::before,
    [type='checkbox'] + label::after {
      border-color: $secondary-color;
    }
  }

  input[type='checkbox'] + label::before,
  [type='checkbox'] + label::after {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    border: 1px solid $border-color;
    width: $check-size;
    height: $check-size;
    border-radius: $border-radius;
    color: $white;
    display: block;
    background-color: $xlight-grey;
    transition: all 0.25s;
  }

  input[type='checkbox']:disabled + label {
    &,
    label {
      cursor: not-allowed;
    }
  }

  input[type='checkbox']:disabled + label::before,
  [type='checkbox']:disabled + label::after {
    background-color: $xlight-grey;
    border-color: $xlight-grey;
    cursor: not-allowed;
  }

  [type='checkbox'] + label::after {
    background-image: url(/images/controls/check.svg);
    background-repeat: no-repeat;
    background-size: 65% 65%;
    background-color: $secondary-color;
    transition: all 0.25s;
    background-position: center center;
    border-color: $secondary-color;
  }

  input[type='checkbox']:not(:checked) + label::after {
    opacity: 0;
  }

  .checkbox-component input[type='checkbox']:checked + label::after {
    opacity: 1;
  }

  &.status-filter {
    margin-bottom: 0;
    padding: 12px 10px 12px;
    input[type='checkbox'] + label::before {
      border-color: grey;
      border-radius: 0;
    }
    .tag {
      position: relative;
      top: -4px;
      min-width: 150px;
    }
    .label {
      padding-bottom: 5px;
    }
  }
}
