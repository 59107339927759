@import '../../styles/variables.scss';

.tooltipWrapper {
  position: relative;
  display: inline-block;

  .label {
    margin-bottom: 0;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: $text-color-alt;
  }
}

.tooltip {
  width: 360px;
  position: absolute;
  height: auto;
  background-color: #fff1bb;
  z-index: 1;
  padding: 1.5rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -100%);
  text-align: center;
  white-space: normal;

  .tooltipText {
    font-size: 13px;
    margin: 0;
    line-height: 1.4;
  }

  .arrowDown {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff1bb;
    position: absolute;
    transform: translateY(100%);
    bottom: 0;
    left: 50%;
  }

  .closeIcon {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 1rem;
    height: 1rem;
    background: url(/images/icons/icon-close-dark.svg) no-repeat center center;
    background-size: 100% 100%;
    text-indent: -9999px;
    opacity: 0.75;
  }
}
