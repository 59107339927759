.admin-accordion-nav {
  .accordion__button {
    color: $text-color;
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    outline: 0;
    font-size: 0.875rem;
    font-weight: 500;
    padding: $aside-gutter;
    cursor: pointer;
    background-image: url(/images/controls/chevron-down-dark.svg);
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: calc(100% - 2rem) center;

    @include long-text-ellipsis;
    padding-right: 3.5rem;
  }

  .accordion__button[aria-expanded='true'] {
    background-image: url(/images/controls/chevron-up-dark.svg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    animation: accordionFadeIn 0.35s ease-in;
    background-color: $white;
    border-top: solid 1px $grey;
    border-bottom: solid 1px $grey;
    padding-top: 1rem;
    padding-bottom: 0;

    .batch-name {
      @include long-text-ellipsis;
      max-width: 200px;
      min-height: 15px;

      @media screen and (max-width: 1025px) {
        max-width: 125px;
      }
    }

    .aside-heading {
      padding-left: 2.55rem;
      &.my-team-members {
        display: inherit;
      }
    }

    .aside-add-new {
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    ul {
      margin: 0 0 1rem 0;
      padding: 0;
    }

    li {
      list-style: none;
      margin: 0;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1;
    }

    a {
      padding: $aside-gutter / 2 $aside-gutter * 1.7;
      padding-right: $aside-gutter / 1.25;
      color: $text-color;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.active {
        color: $white;
        background-color: $secondary-color;

        &:hover {
          background-color: $secondary-color;
        }
      }

      &:hover {
        background-color: $light-blue;
      }

      .number {
        text-align: center;
        display: block;
        width: 2rem;
        text-align: center;
      }
    }
  }
}

@keyframes accordionFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
