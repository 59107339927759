@import 'styles/variables.scss';

.link {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  align-items: center;
  padding: 18px 16px 14px;
  text-decoration: none;

  &:hover {
    background-color: $light-grey;
    text-decoration: underline;
  }

  &:global(.batch-respondent) {
    width: fit-content;

    &:hover {
      background-color: inherit;
    }
  }

  &--alt-text {
    display: inline-flex;
    color: $text-color-alt;
  }
}

.icon {
  display: inline-block;
  height: 16px;
  width: 16px;
  padding: 0px 16px;
  margin-left: -8px;
  background-size: 16px;
}

.reassessment {
  background: url(/images/icons/icon-redo-blue.svg) no-repeat 16px center;
}

.flag {
  background: url(/images/icons/icon-flag.svg) no-repeat 16px center;
}

.circle-arrow {
  background: url(/images/icons/circle-arrow-right.svg) no-repeat 16px center;
}

.link:hover .circle-arrow {
  background: url(/images/icons/circle-arrow-right-active.svg) no-repeat 16px;
}
