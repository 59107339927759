$radio-size: 1.4rem;

.radio-component {
  margin-bottom: $gutterE * 1.1;
  color: $text-color;

  &.large {
    $radio-size: 2rem;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;
  }

  input[type='radio'] + label {
    position: relative;
    padding-left: $radio-size * 1.5;
    cursor: pointer;
    text-transform: none;
    font-weight: normal;
    letter-spacing: unset;
    margin: 0;
    padding-left: $radio-size * 1.45;
    font-size: $radio-size / 1.6;
    font-family: $font-family;
    font-weight: normal;
    display: inline-block;
  }

  &:hover {
    input[type='radio'] + label::before,
    [type='radio'] + label::after {
      border-color: transparent;
    }
  }

  input[type='radio'] + label::before,
  [type='radio'] + label::after {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    background: $white;
    border: 1px solid transparent;
    width: $radio-size;
    height: $radio-size;
    color: $white;
    border-radius: 50%;
    background-color: $light-grey;
  }

  [type='radio'] + label::after {
    background-image: url(/images/controls/radio.svg);
    background-repeat: no-repeat;
    background-color: $light-grey;
    border-color: transparent;
    background-size: $radio-size / 2;
    transition: all 0.25s;
    background-position: center center;
  }

  input[type='radio']:not(:checked) + label::after {
    opacity: 0;
  }

  input[type='radio']:checked + label::after {
    opacity: 1;
  }
}
