@import 'styles/variables.scss';

.banner {
  display: flex;
  align-items: center;
  background-color: $error;
  color: $white;
  padding: 1em 0 1em 0;

  &.feature {
    background-color: $light-navy;

    .content {
      padding-left: calc(20% + 42px); // account for close-btn
    }
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }

  & .content {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    padding: 0 20% 0 20%;
  }

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    border: none;
    cursor: pointer;
    width: 34px;
    height: 34px;
    color: inherit;
    margin-right: 8px;
  }
}
