// General
$gutter: 4rem;
$gutterR: 1rem;
$gutterE: 1em;
$border-radius: 0.25rem;
$container-width: 1280px;
$container-width-small: 800px;
$transition: all 0.25s ease-in-out;
$box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
$box-shadow-light: 0 2px 7px 0 rgba(40, 40, 40, 0.05);
$drop-shadow: drop-shadow($box-shadow);

// Typography
$font-family: "Inter", "Helvetica", arial, sans-serif;
$line-height: 1.6;
$font-size: 16px;
$button-font-size: 0.95rem;
$subhead-font-size: 0.95rem;
$body-font-size: 1rem;

// Colors
$primary-color: #448fff;
$secondary-color: #1b324a;
$tertiary-color: red;

$white: #fff;
$black: #1c1c1c;
$grey: #e8e8e9;

$light-green: #e1efb0;
$light-blue: #ebf4fd;
$light-green: #f5faf3;
$mint-green: #d4e6d4;
$dark-green: #0c6610;
$light-grey: #efefef;
$bg-grey: #fafafa;
$mustard: #f8c437;
$dark-grey: #82867d;
$middle-grey: #dfdddd;

$body-color: white;
$border-color: #efefef;
$border-color-alt: #e1e7f0;
$text-color: #272727;
$text-color-alt: #7a7a7c;
$heading-color: $black;

$brand-color-1: #448FFF;
$brand-color-2: #1B324A;
$brand-color-3: #EFF6FF;
$brand-color-4: #D8E7FF;


$success: #74d2b6;
$warning: #fbb231;
$error: #F67C47;

// general
$card-radius: 10px;

// For Loading Indicator
$dotWidth: 10px;
$dotHeight: 10px;
$dotRadius: $dotWidth / 2;

$dotColor: $primary-color;
$dotBgColor: $dotColor;
$dotBeforeColor: $dotColor;
$dotAfterColor: $dotColor;

$dotSpacing: $dotWidth + $dotWidth / 2;

// layout
$desktop-large-min-width: 1400px;
$desktop-max-width: 1200px;
$desktop-min-width: 1025px;
$tablet-max-width: 1024px;
$tablet-min-width: 768px;
$phone-max-width: 767px;
$phone-lrg-width: 376px;
$phone-min-width: 320px;
$desktop-large: "screen and (min-width: #{$desktop-large-min-width})";
$desktop: "screen and (min-width: #{$desktop-min-width})";
$tablet-desktop: "screen and (min-width: #{$tablet-min-width})";
$tablet-landscape-desktop: "screen and (min-width: #{$tablet-max-width})";
$tablet: "screen and (min-width: #{$tablet-min-width}) and (max-width: #{$tablet-max-width})";
$phone-tablet: "screen and (max-width: #{$tablet-max-width})";
$phone: "screen and (max-width: #{$phone-max-width})";
$phone-landscape: "screen and (max-width: #{$phone-max-width}) and (orientation: portrait)";
$phone-large: "screen and (min-width: #{$phone-lrg-width}) and (max-width: #{$phone-max-width})";
$phone-small: "screen and (max-width: #{$phone-min-width})";
$phone-to-desktop-max: "screen and (max-width: $desktop-max-width})";
$phone-to-desktop-max: "screen and (max-width: $desktop-max-width})";

@mixin for-phone-only {
  @media (max-width: $phone-max-width) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $tablet-min-width) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $tablet-max-width) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $desktop-max-width) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: $desktop-large-min-width) {
    @content;
  }
}
