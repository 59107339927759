.icon-wrap {
  display: inline-block;
  width: 3rem;
  height: 3rem;

  svg.icon {
    display: block;
    width: 100%;
    height: 100%;
    stroke: currentColor;
    fill: transparent;
  }
}
