.body-images {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;

  @media #{$small-tablet} {
    margin-bottom: 0.5rem;
  }

  .image-number {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

    @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
      font-size: 1.15rem;
    }
  }

  .image-assets-wrapper {
    & + .image-assets-wrapper {
      margin-left: 1rem;

      @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
        margin-left: 0.5rem;
      }
    }
  }
}

.legend-images {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  &.single {
    .image-container {
      max-height: 150px;
    }
  }
}

// Image Container styling for legend/question body images for different subtests

// Defaults
.image-container {
  position: relative;
  height: 1000px;
  width: 1000px;

  img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: medium none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
.legend-images .image-container {
  max-width: 550px;
  max-height: 240px;

  @media #{$small-tablet} {
    max-width: 440px;
  }
}
.body-images .image-container {
  max-width: 360px;
  max-height: 240px;

  @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
    max-height: 210px;
  }

  @media #{$small-tablet} {
    max-height: 190px;
  }
}

// Subtest specific
.subtest-digit-symbol .body-images .image-container {
  height: 50px;
  width: 50px;
}

.subtest-spatial .image-container,
.horizontal-question-answer-images .image-container {
  width: 122px;
  height: 158px;

  padding-bottom: 1em;
  border-right: 3px solid black;
  margin-top: 2.5rem;
  display: flex;
  align-items: flex-end;
}

@mixin arrangement-width-height($number) {
  $dimensions: calc((100vw / #{$number}) - (#{$number} * 2px));
  width: $dimensions;
  height: $dimensions;
}

.subtest-pic-arrangement .image-container {
  width: 200px;
  height: 200px;
  border: 1px solid $black;

  @media screen and (max-width: 1326px) {
    &.pic-arrangement-length-6 {
      $dimensions: calc((100vw / 6) - (6 * 4px));
      width: $dimensions;
      height: $dimensions;
    }
  }

  @media screen and (max-width: 1080px) {
    height: 150px;
    width: 150px;
  }

  @media screen and (max-width: 840px) {
    width: 130px;
    height: 130px;
  }

  @media (max-width: 760px) {
    width: 144px;
    height: 144px;

    &.pic-arrangement-length-3 {
      @include arrangement-width-height(3);
    }

    &.pic-arrangement-length-4 {
      @include arrangement-width-height(4);
    }

    &.pic-arrangement-length-5 {
      @include arrangement-width-height(5);
    }

    &.pic-arrangement-length-6 {
      @include arrangement-width-height(6);
    }
  }
}

.disable-edge-hover-menu {
  pointer-events: none;
}

.fade-in {
  animation: fadein 700ms;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
