.avatar-wrap {
  .avatar {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    background-color: $primary-color;
    color: #fff;

    &.hasImage {
      background-color: transparent;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .initial {
      margin: auto;
      font-size: 1.1em;
      text-transform: uppercase;
      line-height: 1;
    }
  }
}
