.test-score-table {
  table-layout: fixed;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.9rem;
  margin: 1rem 0;
  max-width: 855px;
  width: 100%;
  position: relative;

  &.small-table {
    width: fit-content;
  }

  &.facet-table {
    margin-bottom: 2.5rem;
  }

  .wide {
    width: 15rem;
  }

  .med {
    width: 6rem;
  }

  caption {
    font-weight: bold;
    position: absolute;
    right: 5%;
    top: 0;
  }

  th,
  td {
    padding: 0.1875rem;
  }

  tr {
    height: 3rem;

    td span {
      margin-right: 0.25rem;

      &.italics {
        font-style: italic;
      }
    }
  }

  .tc {
    width: 5.5rem;

    &.validity-response {
      width: 13rem;
    }
  }

  th {
    vertical-align: bottom;
    line-height: 1.1;
    position: relative;
    font-weight: 600;
    font-size: 0.8rem;

    &.vt {
      vertical-align: bottom;
    }
  }

  td {
    position: relative;

    &.validity {
      min-width: 300px;
      width: 100%;
    }
  }

  .bar {
    position: absolute;
    height: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: $primary-color;
  }

  .score {
    position: absolute;
    transform: translateX(calc(100% + 10px));
    right: 0;
  }
}

span.facet-heading {
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 1rem;
}
