.search-module {
  position: relative;
  z-index: 10;

  .search-box {
    border-radius: 4px;
    max-width: 273px;
    background-color: $light-grey;
  }

  .search-input {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      flex: 1;
      font-size: 0.875rem;
      font-family: $font-family;
      padding: 0;
      border: 0;
      padding: 12px;
      padding-left: 3rem;
      outline: 0;
      background: url(/images/icons/icon-search-dark.svg) no-repeat 1rem center;
      background-size: 20px;
    }
  }

  .search-results {
    border-radius: 4px;
    border: 1px solid $grey;
    position: absolute;
    margin-top: 9px;
    z-index: 1000;
    width: 100%;
    overflow: auto;
    background-color: $white;

    font-size: 0.95rem;
    font-family: $font-family;
    color: $text-color;

    .result:not(:last-child) {
      border-bottom: 1px solid $grey;
    }

    .link {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 18px 16px 14px;

      &:hover {
        background-color: $light-grey;
        text-decoration: underline;
        font-weight: 600;
      }
    }

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text-item {
      display: flex;
      align-items: center;
      padding: 18px 16px 14px;
    }

    .person:not(:last-child) {
      border-bottom: 1px solid $grey;
    }

    .icon {
      display: inline-block;
      height: 16px;
      width: 16px;
      padding: 0px 16px;
      margin-left: -8px;
      background-size: 16px;
    }

    .reassessment {
      background: url(/images/icons/icon-redo-blue.svg) no-repeat 16px center;
    }

    .circle-arrow {
      background: url(/images/icons/circle-arrow-right.svg) no-repeat 16px
        center;
    }

    .link:hover .circle-arrow {
      background: url(/images/icons/circle-arrow-right-active.svg) no-repeat
        16px;
    }
  }

  .see-all {
    color: $light-navy;
    display: flex;
    justify-content: space-between;
  }
}
