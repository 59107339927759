.action-menu {
  white-space: nowrap;
  background: $white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translate(-50%, 100%);
  transition: all 0.25s;
  box-shadow: 5px 5px 5px $grey;
  z-index: 99;

  &.show-more {
    left: -111%;
    .triangle {
      left: 86%;
    }

    &.anonymization-enabled {
      left: -149%;
      .triangle {
        left: 89%;
      }

      &.extra-space {
        left: -160%;
        .triangle {
          left: 90%;
        }
      }
    }
  }

  .triangle {
    width: 0;
    height: 0;
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $border-color;
    top: -8px;
    left: calc(50% - 0.5rem);
  }

  .triangle:before,
  .triangle.fill-notch.fill-disabled:before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    right: -6.75px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
  }

  .triangle.fill-notch {
    // used to match background color when topmost <li> is hovered
    &:before {
      border-bottom-color: $mid-blue;
    }
  }

  &.right-align {
    left: unset;
    transform: translate(0, 100%);
    right: 0;

    .triangle {
      left: 90%;
    }
  }

  ul {
    margin: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 0.875rem;

    &.anonymize-data {
      display: flex;
      align-items: center;

      .icon-wrap {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
      }

      &.disabled {
        color: rgba($text-color-alt, 50%);
        border-color: currentColor;
        background-color: transparent;
        cursor: default;

        &:hover {
          color: rgba($text-color-alt, 50%);
          border-color: currentColor;
          background-color: transparent;
        }
      }
    }
  }

  li:hover {
    background-color: $mid-blue;
  }

  li:not(:first-child) {
    border-top: 1px solid $border-color;
  }
}
