.archive-nav {
  ul {
    margin: 0;

    li {
      list-style: none;
      margin: 0;
      font-size: 1rem;
      border-bottom: solid 1px $grey;
      font-size: 0.875rem;

      &:last-child {
        border: none;
      }

      a {
        text-decoration: none;
        padding: 1rem;
        display: block;
        color: $text-color;

        &:hover,
        &.active,
        &.active:hover {
          background-color: $secondary-color;
          color: $white;
        }

        &:hover {
          background-color: $light-blue;
          color: $text-color;
        }
      }
    }
  }
}
