.tag {
  background-color: $grey;
  border-radius: 1.5rem;
  padding: 0.3rem 1rem;
  min-width: 80px;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
  text-align: center;
  color: $text-color-alt;
  text-decoration: none;
  white-space: nowrap;

  @media #{$phone-tablet} {
    min-width: 70px;
    font-size: 0.75rem;
  }

  &.outline {
    background-color: $white;
    border: 1px solid $grey;
    color: $light-navy;
  }

  &.light-blue {
    background-color: $light-blue;
    color: $text-color;
  }

  &.light-grey {
    background-color: $light-grey;
    color: $text-color;
  }

  // "Waiting for Activation"
  &.active-light-blue {
    background-color: $light-blue;
    color: $text-link-color;
  }

  &.light,
  &.draft {
    background-color: $mid-blue;
    color: $primary-color;
  }

  &.dark,
  &.live {
    background-color: $primary-color;
    color: $white;
  }

  &.green,
  &.complete {
    color: $white;
    background-color: $success-darker;
  }

  &.grey,
  &.archived {
    color: $text-color-alt;
    background-color: $grey;
  }

  &.red {
    color: $error;
    background-color: rgb($error, 30%);
  }

  &.light-green {
    color: $success;
    background-color: rgb($success, 30%);
  }

  &.circle {
    $tag-size: 30px;
    min-width: 0;
    width: $tag-size;
    height: $tag-size;
    border-radius: calc(#{$tag-size} / 2);
    line-height: $tag-size;
    padding: 0;
  }

  &.width-auto {
    min-width: 10px;
    width: auto;
  }
  &.width-count {
    min-width: 25px;
    margin-left: 4px;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.7rem;
    font-weight: 700;
  }
}
