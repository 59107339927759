/* =============================================================================
Global Footer
============================================================================= */

.footer {
    padding: 5rem 0 3rem;
    
    @include for-tablet-landscape-up {
        padding: 6.25rem 0;
    }

    &-grid {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    &-logo {
        width: 188px;
        height: auto;
    }

    &-contact {
        flex-basis: 100%;
        margin: 2rem 0 0;

        @include for-tablet-portrait-up {
            margin: 0 0 0 6rem;
            flex-basis: auto;
        }

        a {
            display: block;
            color: $brand-color-2;
            font-size: 14px;
        }
    }

    &-copyright {
        font-size: 14px;
        color: $text-color-alt;
        flex-basis: 100%;
        margin: 2rem 0 0;

        p {
            display: inline;
        }
        
        @include for-desktop-up {
            margin: 0 0 0 auto;
            flex-basis: auto;

            p {
                display: block;
            }
        }
    }
}
