.input-component {
  position: relative;
  margin-bottom: $gutterE * 1.5;

  .extra-hint {
    display: none;
  }

  &:focus-within {
    .extra-hint {
      display: inline;
      color: $text-color-alt;
      margin-left: 0.75rem;
    }
  }

  &.date-input {
    position: static;
  }

  &.date input {
    background-image: url(/images/icons/icon-cal-dark.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem);
    background-size: 1rem 1rem;
  }

  &.time input {
    background-image: url(/images/icons/icon-clock-dark.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem);
    background-size: 1rem 1rem;
  }

  &.no-margin {
    margin-bottom: 0;
  }

  &.small {
    width: 7rem;
  }

  &.small-date {
    max-width: 11rem;
  }

  &.med {
    width: 11rem;
  }

  &.center {
    input {
      text-align: center;
    }
  }

  &.no-background {
    input {
      background-color: transparent;
    }
  }

  .date-input-custom {
    @extend .button-reset;
    text-align: left;
    background-image: url(/images/icons/icon-cal-dark.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem);
    background-size: 1rem 1rem;
    width: 100%;
    padding: 0.75em;
    border-radius: $border-radius;
    background-color: $light-grey;
    font-size: 1em;
    font-family: $font-family;
    font-weight: normal;
    color: $text-color, 45%;
    line-height: 1.25;

    &.has-errors {
      background-image: url(/images/icons/icon-error-red.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      background-size: 1rem 1rem;
      border: 1px solid $error;
    }

    .placeholder {
      color: lighten($text-color, 45%);
    }
  }

  label {
    margin: 0 0 0.3em 0;
    display: block;
    color: fade($text-color, 90%);
    font-size: 0.875em;
    letter-spacing: 0.05em;
    font-weight: 500;
  }

  .inline-tooltip {
    margin-bottom: 0.3em;
    margin-left: 5px;
    font-size: 11px;
  }
  .input-field-wrapper {
    position: relative;
  }

  input {
    width: 100%;
    padding: 0.75em;
    border: none;
    outline: none;
    border: none;
    border-radius: $border-radius;
    background-color: $light-grey;
    font-size: 1em;
    font-family: $font-family;
    font-weight: normal;
    -webkit-appearance: none;
    color: $text-color;

    &::placeholder {
      color: lighten($text-color, 45%);
    }

    &.error {
      background-image: url(/images/icons/icon-error-red.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      background-size: 1rem 1rem;
    }
  }

  &.password.has-errors input {
    background-position: calc(100% - 2rem);
  }

  .password-preview {
    background: none;
    border-width: 0;
    -webkit-appearance: none;
    background: url('/images/controls/eye.svg');
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: none;
    font-size: 0;
    color: transparent;
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
  }

  input[type='date'],
  input[type='time'] {
    padding-top: 0.66em;
    padding-bottom: 0.66em;
  }

  &.has-errors {
    input {
      background-image: url(/images/icons/icon-error-red.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      background-size: 1rem 1rem;
      border: 1px solid $error;
    }
  }

  &.underline {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    input {
      text-align: center;
      border-radius: 0;
      background-color: transparent;
      font-size: 1.5em;
      border-bottom: solid 3px currentColor;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.password-checklist {
  li {
    svg {
      position: relative;
      top: 6px;
      border-radius: 50%;
      background: $grey;
      padding: 3px;
      margin-right: 0.5rem;
    }

    &.valid svg {
      background: $success;

      path {
        fill: $white;
      }
    }

    &.invalid {
      svg path {
        display: none;
      }
    }
  }
}
