& {
  font-size: $font-size;
  line-height: $line-height;
  font-family: $font-family;
  color: $text-color;
  background-color: $body-color;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.65em;
  font-weight: 700;
  line-height: 1.3;
  color: $heading-color;
  font-family: $font-family;
  letter-spacing: 0.2px;

  &.underline {
    border-bottom: solid 1px $border-color;
    padding-bottom: 2em;

    @media #{$phone} {
      padding-bottom: 0.5em;
    }
  }
}

.fweight-400 {
  font-weight: 400 !important;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.secondary-color {
  color: $secondary-color;
}

h1 {
  font-size: 1.5625em;
}

h2,
.h2 {
  font-size: 1.25em;

  &.isRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$phone} {
      .btn {
        min-width: unset;
      }
    }
  }
}

h3 {
  font-size: 1.125em;
  font-weight: 700;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 1em;
  font-weight: 500;
}

h6 {
  font-size: 0.75rem;

  &.caption-bold {
    font-weight: 600;
  }

  &.small-caption {
    font-weight: normal;
    font-size: 0.625em;
  }
}

hr {
  border: 0;
  height: 1px;
  background: $grey;
}

address {
  font-weight: 300;
}

strong {
  font-weight: 700;
}

small {
  font-size: smaller;
}

p {

  &.medium-weight {
    font-weight: 500;
  }

  &.smaller {
    font-size: 0.8125em;
  }
}

a {
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

b {
  font-weight: 500;
}

ul {
  margin: 0 0 1.45em 1em;
  padding: 0;
  list-style: disc outside;
}

ul ul {
  margin: 1em 0 1em 1em;
}

ol ul {
  margin: 1em 0 1em 1em;
}

ol ol {
  margin: 1em 0 1em 1.25em;
}

ul li {
  list-style: outside;
  margin: 0 0 0.2em 0;
}

ol {
  margin: 0 0 1em 1em;
  list-style-type: decimal;
}

ol li {
  margin: 0 0 0.5em 0;
}

li li {
  font-size: 1em;
}