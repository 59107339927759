.help-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  p {
    margin: 0;
    flex: 1;
    font-size: 0.875rem;
  }

  .icon-wrap {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: 0.1rem 0.85rem 0 0;

    svg.icon {
      display: block;
      width: 100%;
      height: 100%;
      stroke: currentColor;
      fill: transparent;
    }
  }

  &.complete-batch-modal {
    background-color: $light-blue;
    padding: 1em;
    margin-bottom: 1em;
    border-radius: $border-radius;

    .icon-wrap {
      margin: auto 0.85rem auto 0;
    }
  }
}

.password-reset-info {
  font-size: 0.875em;
  color: #7a7a7c;
  margin-top: 1.7em;
}
