@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.loading-ellipsis.center {
  display: inline-block;
  width: calc(100vw - 314px); // subtract admin aside width
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  & + p {
    top: 54%;
  }
}

.loading-ellipsis.center-full-page {
  display: inline-block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  & + p {
    margin-top: 2rem;
  }
}

.loading-ellipsis.middle {
  display: inline-block;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.loading-ellipsis span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  font-size: 1.5rem;
}
.loading-ellipsis span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-ellipsis span:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-ellipsis.btn-type span {
  font-size: 1rem;
}
