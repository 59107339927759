.pagination {
  max-width: 320px;
  margin: 3rem auto 1rem;
  display: flex;
  justify-content: center;

  &-dots {
    color: $text-color-alt;
  }

  ul {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  li {
    list-style: none;
    margin: 0 0.8rem;
  }

  a {
    color: $text-color-alt;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }

  .text {
    margin: 0 1rem;
  }

  .active {
    background-color: transparent;
    font-weight: 500;
    color: $text-color;
    padding: 0;
  }

  .disabled:hover {
    cursor: default;
  }
}
