#sandbox {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

#demo-row {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

#demo-button {
  margin-right: 2rem;
}
