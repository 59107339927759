@import 'styles/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  border-radius: 4px;
  background: #fde5da;

  .warning-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    :global(.icon-wrap) {
      width: 30px;
      height: 30px;
      color: transparent;
    }
  }

  p {
    margin: 0;
  }
}
