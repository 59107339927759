.help-box {
  display: block;
  width: 1.3rem;
  height: 1.3rem;
  margin: auto;
  margin-bottom: 0.5rem;
  position: relative;

  &.inline {
    display: inline-block;
    margin: 0;
    margin-bottom: -0.25rem;
  }

  .icon-wrap {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    svg.icon {
      display: block;
      width: 100%;
      height: 100%;
      stroke: currentColor;
      fill: transparent;
    }
  }

  .tip {
    position: absolute;
    max-width: 350px;
    height: auto;
    background-color: #fff1bb;
    z-index: 1;
    padding: 1.5rem;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    top: -5px;
    left: 0;
    transform: translate(-50%, -100%);
    text-align: center;
    white-space: normal;

    .arrow-down {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff1bb;
      position: absolute;
      transform: translateY(100%);
      bottom: 0;
      left: 50%;
    }

    button {
      @extend .button-reset;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.2rem;
      height: 1.2rem;
      background: url(/images/icons/icon-close-dark.svg) no-repeat center center;
      background-size: 100% 100%;
      text-indent: -9999px;
      opacity: 0.75;
    }

    p {
      font-size: 13px;
      margin: 0;
      line-height: 1.5;
      text-align: left;
      text-wrap: wrap;
      width: max-content;
      max-width: fit-content;
    }
  }
}
