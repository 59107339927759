.table-styled {
  border: solid 1px $grey;
  border-radius: $border-radius;
  overflow: hidden;

  .icon-wrap {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    position: relative;

    svg.icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      stroke: currentColor;
      fill: transparent;
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 0.875rem;

    @media #{$phone-tablet} {
      font-size: 0.8125rem;
    }

    p {
      margin: 0;
    }

    tr {
      border-bottom: 1px solid $grey;

      &.selected {
        background-color: $light-grey;
      }

      &:last-child {
        border: none;
      }
    }

    th.check,
    td.check,
    div.check {
      padding-left: 1.5rem;
      padding-right: 0;

      @media #{$phone-tablet} {
        padding-left: 1rem;
      }

      .checkbox-component {
        margin: 0;

        input[type='checkbox'] + label {
          display: inline;
        }
      }
    }

    td,
    th {
      padding: 1.5rem;

      @media #{$phone-tablet} {
        padding: 0.75rem;
      }
    }

    td {
      &.status {
        white-space: nowrap;
        min-width: 150px;

        @media #{$phone-tablet} {
          min-width: auto;
        }
      }

      &.user,
      &.link {
        white-space: nowrap;

        @media #{$phone-tablet} {
          white-space: normal;
        }

        a {
          text-decoration: none;
          color: $text-color;
          display: flex;
          align-items: center;
          line-height: 1.2;

          .icon-wrap {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            position: relative;
            background-color: $grey;
            margin-left: 0.75rem;
            border-radius: 50%;

            @media #{$phone-tablet} {
              display: none;
            }

            svg.icon {
              display: block;
              width: 65%;
              height: 65%;
              stroke: $text-color-alt;
              fill: transparent;
            }
          }

          &:hover {
            text-decoration: underline;
            color: $black;

            .icon-wrap {
              background-color: $secondary-color;

              svg.icon {
                stroke: $white;
              }
            }
          }
        }
      }

      &.boolean p {
        min-width: 124px;
        color: $dark-navy;
        display: flex;
        align-items: center;
        line-height: 1.2rem;

        .icon-wrap {
          display: inline-block;
          width: 1rem;
          height: 1rem;
          position: relative;
          align-self: center;
          margin-left: 0.3rem;
        }
      }

      &.summary {
        p {
          padding-right: 40%;

          @media screen and (max-width: 1062px) {
            padding-right: 0;
          }
        }
      }
    }

    th {
      font-size: 1rem;
      text-align: left;
      background-color: $light-grey;
      white-space: nowrap;

      @media #{$phone-tablet} {
        font-size: 0.875rem;
      }
    }

    .sortable {
      text-decoration: none;
      position: relative;
      &:hover {
        cursor: pointer;
      }
      &:after {
        content: url(/images/controls/chevron-sort.svg);
        display: inline-block;
        color: $text-color-alt;
        width: 0.6rem;
        height: 0.6rem;
        position: absolute;
        top: 0;
        right: -15px;
      }
    }

    .checkmark:after {
      content: url(/images/controls/check-dark.svg);
      display: inline-block;
      width: 0.9rem;
      height: 0.9rem;
      margin: 0 0.4rem;
    }
  }

  .no-batches {
    font-size: 1rem;
  }

  .new-batch {
    font-weight: 500;
    font-size: 1rem;
    color: $dark-navy;
    padding: 0;

    a {
      padding: 1.5rem;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .icon-wrap {
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 5px;
    }
  }

  &.table-search-results {
    tbody tr:hover {
      background-color: $light-grey;
    }
  }

  .text {
    @include long-text-ellipsis;
    max-width: 300px;
    @media screen and (max-width: 1025px) {
      max-width: 200px;
    }
  }

  &.project-overview-batches {
    .text {
      max-width: 600px;

      @media screen and (max-width: 1025px) {
        max-width: 400px;
      }
      @media screen and (max-width: 870px) {
        max-width: 300px;
      }
    }
  }

  &.batch-respondents-table {
    border: none;

    thead {
      tr {
        display: flex;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background-color: $light-grey;

        th {
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }

    tbody {
      tr {
        display: block;
        border: solid 1px $border-color;
        border-radius: $border-radius;
        margin-top: 0.7rem;
      }
    }

    th.check,
    div.check {
      .checkbox-component {
        input[type='checkbox'] + label::before {
          background-color: $white;
        }
      }
    }

    .respondent-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 1.5rem 1rem 0;

      .arrow-wrap {
        padding-left: 1rem;
        padding-right: 0.75rem;
        cursor: pointer;
      }

      .arrow {
        border: solid black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        transition: transform 300ms;
      }

      .arrow.up {
        transform: rotate(-135deg);
      }

      .arrow.down {
        transform: rotate(45deg);
      }

      .user {
        flex-grow: 1;

        .text {
          vertical-align: bottom;
        }

        .icon-wrap {
          display: inline-block;
          vertical-align: middle;
          width: 1rem;
          height: 1rem;
          position: relative;
          background-color: $grey;
          margin-left: 0.75rem;
          border-radius: 50%;

          @media #{$phone-tablet} {
            display: none;
          }

          svg.icon {
            display: block;
            width: 65%;
            height: 65%;
            stroke: $text-color-alt;
            fill: transparent;
          }
        }
      }

      .info {
        display: flex;
        justify-content: space-between;
        min-width: 275px;

        @media #{$phone-tablet} {
          min-width: 240px;
        }

        .re-entry-info {
          display: flex;
          flex-flow: row nowrap;

          p {
            display: flex;
            justify-content: flex-start;
            line-height: 2rem;
            min-width: 200px;
            margin-right: 0.5rem;
          }

          .not-selectable {
            user-select: none;
            margin-right: 0.35rem;
          }

          .resume-code {
            color: $text-color-alt;
          }

          .copy {
            margin-right: 2rem;
          }
        }
      }
      .assessment-status {
        ul {
          margin-bottom: -4px;
          list-style: none;
        }
        ul li {
          display: inline-flex;
          justify-content: space-between;
          padding-right: 5px;
          min-width: 50px;

          .tag {
            display: inline-flex;
            position: relative;
            padding-left: 5px;
            margin-left: 7px;
            margin-right: 5px;
            top: 5px;
          }
        }
        min-width: 6.646rem;
      }
    }
  }

  &.batch-respondent-entry-table {
    display: block;
    border: none;
    border-top: 1px solid $border-color;
    border-radius: 0;
    padding: 0 1.5rem 1.5rem 1.5rem;

    table {
      border-collapse: separate;
    }

    thead {
      tr {
        display: table-row;
        border: none;

        th {
          text-align: left;
          background-color: $white;
          border-radius: 0;
          padding-top: 0.6rem;
          padding-bottom: 0.6rem;
          font-size: 0.85rem;
          color: darkgrey;
        }
      }
    }

    tbody {
      tr {
        display: table-row;
        margin: 0;
        border-spacing: 0;

        // all this for rounded corners...
        // https://stackoverflow.com/questions/628301/css3s-border-radius-property-and-border-collapsecollapse-dont-mix-how-can-i
        td {
          border-top: 1px solid $border-color;
          background-color: $light-grey;
          padding-top: 0.7rem;
          padding-bottom: 0.7rem;
        }

        td:first-child {
          border-left: 1px solid $border-color;
        }

        td:last-child {
          border-right: 1px solid $border-color;
        }
      }

      tr:first-child {
        td:first-child {
          border-top-left-radius: $border-radius;
        }

        td:last-child {
          border-top-right-radius: $border-radius;
        }
      }

      tr:last-child {
        td {
          border-bottom: 1px solid $border-color;
        }

        td:first-child {
          border-bottom-left-radius: $border-radius;
        }

        td:last-child {
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }
}

.table-status-filter {
  table {
    border: none;
  }
  th {
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding-top: 7px;
  }
  th:last-child {
    border-right: none;
  }

  table td {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  table td:first-child {
    border-left: none;
  }

  table td:last-child {
    border-right: none;
  }
}

.respondent-assessments-table {
  .row {
    &:hover {
      background-color: #f5f7fa;
    }
  }

  a.link-row {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      font-weight: 600;
      color: #7a7a7c;
      font-size: 1em;
      margin-right: 0.75em;
    }

    .link-right-arrow {
      width: 1.3em;
      height: 1.3em;
      text-indent: -9999px;
      @extend .button-reset;
      background: url(/images/icons/circle-arrow-right.svg) no-repeat center;
      background-size: contain;
    }

    &:hover {
      .text {
        color: $secondary-color;
      }

      .link-right-arrow {
        background-image: url(/images/icons/circle-arrow-right-active.svg);
      }
    }
  }

  .delete {
    display: flex;
    cursor: pointer;

    &__text {
      color: #bcbcbd;
    }

    &__icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url('/images/icons/icon-trash-grey.svg');
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 5px;
    }

    &:hover {
      .delete__text {
        color: $error !important;
      }

      .delete__icon {
        background-image: url('/images/icons/icon-trash-orange.svg') !important;
      }
    }
  }
}

.completion-history-table,
.reports-generated-table {
  .row {
    &:hover {
      background-color: #f5f7fa;

      .user {
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}

.purchase-reports {
  flex-basis: 100%;

  td.quantity-input {
    display: flex;
    align-items: center;
  }

  .input-component {
    min-width: 4rem;
    width: 4rem;
    margin-bottom: 0;

    &.has-errors input {
      background-position: 90%;
    }
  }

  .quantity-hint {
    font-size: 0.8rem;
    color: $text-color-alt;
    max-width: 350px;
    margin-left: 1.5rem;
  }

  input {
    text-align: center;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
