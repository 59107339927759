@import 'styles/variables.scss';

.modal-content {
  display: flex;
  justify-content: center;
}

.actionsWrapper :global(.modal-actions) {
  background-color: $white;
}
.image {
  height: 75vh;
  display: block;
  max-width: 75vw;

  object-fit: contain;
}
