// General Form Styles
@-webkit-keyframes autofill {
  to {
    color: #666;
    background: $white;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

form {
  margin: 0;
}

label {
  user-select: none;
}

.label {
  margin-bottom: 1.1em;
  display: block;
  color: fade($text-color, 90%);
  font-size: 0.8em;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.field-hint {
  color: fade($text-color, 40%);
  font-size: 0.74em;
  font-weight: normal;
  display: block;
  margin-top: 0.5rem;

  &:empty {
    display: none;
  }

  &.is-error {
    text-align: left;
    color: $error;
  }

  &.center {
    text-align: center;
    margin: 0.5em 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
}

.input-component,
.radio-component,
.textarea-component,
.checkbox-component {
  & + .field-hint {
    margin: -1em 0 1em 0;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  margin-bottom: $gutterE * 1;
  margin-top: $gutterE * 2;

  legend {
    font-weight: 600;
    color: $text-color;
    font-size: 0.889em;
    margin-bottom: $gutterE * 2.5;
    padding-bottom: $gutterE;
    border-bottom: solid 1px $border-color;
    width: 100%;
  }
}

.form-row {
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  @media #{$phone} {
    > .input-component + .input-component {
      margin-left: 0;
    }
  }

  @include for-tablet-portrait-up {
    flex-wrap: nowrap;

    > * + * {
      margin-left: 1.5em;
    }
  }
}

.form-actions {
  padding-bottom: 5em;
  display: flex;
  justify-content: center;

  .btn {
    margin: 0 1em;
  }
}
