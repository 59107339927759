.expandable-search-button {
  display: flex;
  margin-left: 1rem;
  color: $black;
  border-radius: 4px;
  border: 1px solid $black;
  transition: all 0.5 ease;

  label {
    padding: 0.2rem 0;
    opacity: 1;
    font-size: 0.9rem;
    align-self: center;
    padding-right: 1rem;
  }

  .search-input {
    @extend .button-reset;
    font-size: 0.9rem;
    padding: 0.6rem 0;
    padding-left: 2.8rem;
    background: url(/images/icons/icon-search-dark.svg) no-repeat left 0.25rem;
    background-position: 0.8rem;
    background-size: 1.25rem;
    width: 2rem;
    transition: width 0.5s;

    + label {
      cursor: pointer;
    }

    &.open {
      min-width: 200px;
      cursor: text;

      + label {
        display: none;
      }
    }

    &.open ~ .reset-search {
      width: 2rem;

      .icon-wrap {
        display: block;
        opacity: 100;
        transition: opacity ease-in 0.4s;
      }
    }
  }

  .reset-search {
    width: 0;
    padding: 0;

    .icon-wrap {
      opacity: 0;
      transition: none;
    }
  }
}
