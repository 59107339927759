@import '/styles/variables.scss';

.role-group {
  legend {
    font-family: $font-family;
    font-size: 14px;
    padding-bottom: 16px;
    margin-bottom: 0px;
    border-bottom: none;
  }
}

.role-select {
  cursor: pointer;
  font-family: $font-family;
  display: flex;
  align-items: center;
  background-color: $light-grey;
  border-radius: 12px;
  border: 1px solid $grey;
  padding: 23px 18px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .radio {
    display: none;
  }

  .icon {
    padding: 12px;
    margin-right: 18px;
    border-radius: 50%;
    background-color: rgba($success, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &.selected {
    background-color: rgba(#448fff, 0.2);
    border: 1px solid $mid-blue;

    .icon {
      background-color: $dark-navy;
    }
  }

  .text-wrapper {
    flex-grow: 1;
  }

  .role-name {
    font-size: 16px;
    line-height: 22.5px;
    letter-spacing: 0.2px;
    color: $black;
    font-weight: 500;
    margin-bottom: 3px;
  }

  .caption {
    font-size: 14px;
    line-height: 18.5px;
    letter-spacing: 0.2px;
    color: $black;
    margin-bottom: 0;
  }
}
