@import '/styles/variables.scss';
@import '/styles/mixins.scss';

.dashboard-content {
  margin: 64px 50px;

  .greeting {
    margin-bottom: 21px;
  }

  .subtext {
    font-size: 16px;
    line-height: 22.5px;
    margin-bottom: 45px;
    max-width: 562px;
  }

  .instructions {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    font-weight: 500;
    max-width: 775px;
    margin-bottom: 63px;
  }
}

.guide-link {
  max-width: 1013px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $light-blue;
  border-radius: 6px;
  padding: 29px 36px 30px 29px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .name {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .right-content {
    display: flex;
    align-items: center;
  }

  .view-pdf-text {
    text-transform: none;
    color: $text-color;
    letter-spacing: 0;
    margin-bottom: 0;
    margin-right: 12px;
  }

  &:hover .view-pdf-text {
    text-decoration: underline;
  }

  .icon {
    height: 22px;
    width: 22px;
  }
}

.aside-resources {
  margin: 27px;
}

.aside-resource-listing {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }

  &:hover p {
    text-decoration: underline;
  }
}
