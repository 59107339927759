.not-found {

    &-container {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-content: center;
        justify-content: center;
        height: 80vh;
        height: 80svh;
}

    &-title {
        font-size: 4.125rem;
        margin-bottom: 1rem;
    }

    &-subhead {
        color: #448fff;
        font-size: 0.95rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    &-caption {
        max-width: 700px;
        font-size: 1.25rem;
        margin-bottom: 3rem;
    }

    &-content {
        flex: 0 1 auto;
        text-align: center;
        position: relative;
        padding: 4rem 2rem;
        background: $white;
        border-radius: 10px;
        z-index: 2;
    }

    &-bg {}
}