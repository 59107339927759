// General Form Styles
@-webkit-keyframes autofill {
  to {
    color: #ccc;
    background: $white;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

form {
  margin: 0;
  padding: 0;
}

label {
  user-select: none;

  a {
    white-space: nowrap;
  }
}

.input-w-button {
  display: flex;

  .input-component {
    margin: 0;
    flex: 1;

    & + .btn {
      margin-left: 1rem;
    }
  }
}

div.or {
  color: $text-color-alt;
  font-size: 0.875rem;
  text-transform: uppercase;
  position: relative;
  width: 100%;
  display: flex;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;

  span {
    background-color: $white;
    position: relative;
    z-index: 1;
    text-align: center;
    margin: auto;
    padding: 0 2rem;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $grey;
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.label {
  text-transform: none;
  color: $text-color;
  margin: 0 0 0.3rem 0;
  color: fade($text-color, 90%);
  font-size: 0.875em;
  letter-spacing: 0.05em;
  font-weight: 500;
}

.field-hint {
  color: fade($text-color, 40%);
  font-size: 0.8em;
  font-weight: normal;
  display: block;
  margin-top: 0.65rem;

  &:empty {
    display: none;
  }

  &.is-error {
    text-align: center;
    color: $error;
  }

  &.center {
    text-align: center;
    margin: 0.5em 0;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }
}

// React datepicker overrides
.react-datepicker__triangle {
  display: none !important;
}
.react-datepicker-wrapper {
  display: block !important;
}

.input-component,
.radio-component,
.textarea-component,
.checkbox-component {
  & + .field-hint {
    margin: -1em 0 1em 0;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  margin-bottom: $gutterE * 1;
  margin-top: $gutterE * 2;

  legend {
    font-weight: 600;
    color: $text-color;
    font-size: 0.889em;
    margin-bottom: $gutterE * 2.5;
    padding-bottom: $gutterE;
    border-bottom: solid 1px $border-color;
    width: 100%;
  }
}

.form-row {
  display: flex;
  align-items: center;

  .select-wrap,
  .date-input {
    width: 100%;
    max-width: 370px;
  }

  span.or {
    margin: 0 1rem;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
  }

  .input-component + .input-component {
    margin-left: 1em;
  }

  &.with-button {
    height: 145;
    margin-bottom: 2rem;
    align-items: flex-start;

    .btn {
      margin-left: 1rem;
      padding: 0.8rem;
      margin-top: 1.6rem;
    }
  }
}

.form-row-grid {
  display: grid;

  .input-component {
    margin: 0;
  }

  &.test-code,
  &.update-batch-time {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    align-items: start;

    .btn {
      padding-top: 0.83rem;
      padding-bottom: 0.83rem;
      width: 100%;
      margin-top: 1.6em;
    }

    @media screen and (max-width: 1400px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media #{$phone-tablet} {
      grid-template-columns: 1fr;

      .label {
        display: none;
      }

      .btn {
        margin: 0 auto;
        width: unset;
      }
    }
  }

  &.update-batch-time {
    grid-template-columns: repeat(4, 1fr);

    .show-calendar {
      margin-top: 0.85rem;
      background-image: url(/images/icons/icon-cal-dark.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      background-size: 1rem 1rem;
    }

    .show-clock {
      margin-top: 0.85rem;
      background-image: url(/images/icons/icon-clock-dark.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem);
      background-size: 1rem 1rem;
    }
  }
}

.actions {
  &.is-row,
  &.is-column {
    display: flex;
  }

  &.is-row {
    justify-content: space-between;
    align-items: center;
  }

  &.is-column {
    flex-direction: column;
    align-items: center;

    .btn + .btn {
      margin-top: 1em;
    }
  }
}

p.form-desc {
  font-size: 1rem;
  font-weight: 500;

  a {
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
}
