.accordion-archive-filter {
  .accordion__item + .accordion__item {
    margin-top: 2rem;
  }

  .accordion__button {
    cursor: pointer;
    width: 100%;
    text-align: left;
    border: none;
    position: relative;
    outline: 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px $border-color;

    .heading {
      font-size: 0.875rem;
      font-weight: 500;
      color: $text-color;
    }

    .icon {
      width: 1rem;
      height: 1rem;
      background-image: url(/images/controls/chevron-down-dark.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px 8px;
      margin-left: 2rem;
    }
  }

  .accordion__button[aria-expanded='true'] {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    .icon {
      background-image: url(/images/controls/chevron-up-dark.svg);
    }
  }

  .accordion__panel {
    animation: accordionFadeIn 0.35s ease-in;
    margin: 1rem;
  }

  .select-wrap {
    margin-bottom: 1rem;
  }

  .select-wrap + .select-wrap {
    margin-left: 0;
  }
}
