html {
  font-size: $font-size;
  font-family: $font-family;
}

body {
  line-height: $line-height;
  font-family: $font-family;
  color: $text-color;
  background-color: $body-color;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.65em;
  font-weight: 600;
  line-height: 1.3;
  color: $heading-color;
  font-family: $font-family;

  &.underline {
    border-bottom: solid 1px $grey;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    .count {
      width: 1.3rem;
      height: 1.3rem;
      color: $white;
      background-color: $primary-color;
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      line-height: 1.3rem;
      font-size: 0.7rem;
      font-weight: 600;
      margin-right: 0.5rem;
    }
  }
}

.fweight-400 {
  font-weight: 400 !important;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.show-breaks {
  white-space: pre-wrap;
}

.primary-color {
  color: $primary-color;
}

.secondary-color {
  color: $secondary-color;
}

.alt-color {
  color: $text-color-alt;
}

.grey {
  color: $grey;
}

.red {
  color: $red;
}

.light-navy {
  color: $light-navy;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.strong {
  font-weight: 700;
}

h1,
.f1 {
  font-size: 2.813em;
  font-weight: 700;
}

h2,
.f2 {
  font-size: 1.5em;
  font-weight: 600;
}

h3,
.f3 {
  font-size: 1.25rem;
  font-weight: 600;
}

h4,
.f4 {
  font-size: 0.875em;
  font-weight: normal;
}

h5,
.f5 {
  font-size: 0.875em;
  font-weight: 500;
}

h6,
.f6 {
  font-size: 0.875em;
  text-transform: uppercase;
  color: $text-color-alt;
  letter-spacing: 0.08em;
  font-weight: normal;
}

hr {
  border: 0;
  height: 1px;
  background: $grey;
}

address {
  font-weight: 300;
}

strong {
  font-weight: 700;
}

small {
  font-size: smaller;
}

p {
  margin-bottom: 1.3em;

  &.underline {
    border-bottom: solid 1px $grey;
    padding-bottom: 0.5rem;
  }
}

a {
  color: $text-color;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

ul {
  margin: 0 0 1.45em 1em;
  padding: 0;
  list-style: disc outside;
}

ul ul {
  margin: 1em 0 1em 1em;
}

ol ul {
  margin: 1em 0 1em 1em;
}

ol ol {
  margin: 1em 0 1em 1.25em;
}

ul li {
  list-style: outside;
  margin: 0 0 0.2em 0;
}

ol {
  margin: 0 0 1em 1em;
  list-style-type: decimal;
}

ol li {
  margin: 0 0 0.5em 0;
}

li li {
  font-size: 1em;
}

// prevent subscripts/superscripts from affecting line height
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  top: 0.4em;
}

/* Font import for symbols
=================================================== */
@font-face {
  font-family: 'Libertine';
  src: url('/fonts/LinLibertine_R.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.red-instructions {
  color: $red;
  font-weight: bold;
}
