@keyframes lower-in {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.dropdown-user {
  position: relative;

  .user-trigger {
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    background-color: transparent;
  }

  .dropdown-component {
    min-width: 150px;
    top: 100%;
    left: -100px;
    box-shadow: 5px 10px 20px rgb(51 71 91 / 12%);
    border: 0;
    margin-top: 6px;
    border-radius: 10px;

    li {
      border-bottom: solid 1px $border-color;

      &:last-child {
        border: none;
      }

      .icon-wrap {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.35rem;
      }

      a {
        color: $text-color;
        display: block;
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0.7rem 1rem;

        &:hover {
          background-color: $light-green;
        }
      }
    }
  }
}

.dropdown-component {
  display: block;
  z-index: -1;
  background-color: #fff;
  min-width: 240px;
  position: absolute;
  opacity: 0;
  border: solid 1px $border-color;
  margin-top: 10px;
  overflow: hidden;

  &.open {
    animation: lower-in 0.35s;
    opacity: 1;
    z-index: 10;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 1;
    list-style: none;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &.icon {
      a {
        background-repeat: no-repeat;
        background-position: 0.75rem center;
        background-size: 1.2rem 1.2rem;
        padding-left: 2.5rem;
      }

      &.edit a {
        background-image: url(/images/icons/icon-edit-dark.svg);
      }

      &.archive a {
        background-image: url(/images/icons/icon-trash-dark.svg);
      }
    }
  }

  li > a,
  li > button {
    display: block;
    text-decoration: none;
    color: $text-color;
    background-repeat: no-repeat;
    background-position: 1rem 0.5rem;
    background-size: 1.25rem;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;

    &:hover {
      color: $text-color;
    }
  }
}
