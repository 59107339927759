.countdown {
  color: $text-color;
  background-color: $light-blue;
  font-size: 0.875em;
  font-weight: 500;
  border-radius: $border-radius;
  width: 20em;
  height: 3.6em;
  line-height: 3.6em;
  text-align: center;
  position: relative;

  &.warning {
    color: #cc1d0d;
  }

  @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
    font-size: 12px;
  }

  &.hidden {
    display: none;
  }

  .time-bar {
    position: relative;
    bottom: 5px;
    height: 5px;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  @media (max-width: 1280px) {
    width: 12rem;
  }

  @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
    height: 2.8em;
    line-height: 2.8em;
    position: absolute;
    right: 0;
    top: 7px;
    font-size: 13px;
    width: 10rem;

    .time-bar {
      height: 2px;
      bottom: 2px;
    }
  }

  @media #{$small-tablet} {
    width: 9rem;
  }
}
