$card-padding: 16px;

.card-wrapper {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card {
  background-color: $white;
  box-shadow: $box-shadow;
  width: 98%;
  margin: $card-padding auto;
  max-width: 1358px;
  border-radius: 1em;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
    border-radius: 0;
    min-height: unset;
    width: 100%;
    margin: auto;
  }

  .content-wrap {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    &.move-up {
      margin-top: -30px;

      @media screen and (max-height: 700px) {
        margin-top: auto;
      }
    }
  }

  .is-wide,
  .is-med,
  .is-med-wide,
  .is-slim {
    margin-right: auto;
    margin-left: auto;
  }

  .scroll-wrap {
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: calc(25vh - 60px);
      left: 0;
      width: 100%;
      height: 60px;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    .scroll-content {
      max-height: 25vh;
      overflow: auto;
      position: relative;
      padding-bottom: 40px;

      p {
        font-size: 0.875em;
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .content {
    margin: auto;
    width: 92%;
    padding-bottom: 150px;

    .intro {
      @media #{$tablet} {
        font-size: 92%;
      }

      @media #{$phone} {
        font-size: 90%;
      }

      & + .actions {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn + .btn {
          margin-left: 1.2em;
        }
      }
      @at-root {
        .question-actions {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          max-width: 1358px;
          margin: 0 auto;
          background: $white;
          filter: drop-shadow(0px -4px 24px rgba(0, 0, 0, 0.05));

          .actions.is-row {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            .btn + .btn {
              margin-left: 1.2em;
            }
          }
        }
      }
    }

    .backdrop {
      width: fit-content;
      padding: 4rem;
      margin-left: auto;
      margin-right: auto;
      background-color: $mid-blue;
      border-radius: 1em;
    }

    h1 {
      margin-bottom: 0.7em;

      @media #{$phone} {
        font-size: 1.5rem;
      }
    }

    h2 {
      margin-bottom: 2em;
    }

    h3 {
      margin-bottom: 1em;

      @media #{$phone} {
        font-size: 1rem;
      }
    }

    h3.question-body-text {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      min-height: 3.5rem;

      &:empty {
        display: none;
      }
    }

    h6 {
      margin-bottom: 2em;
    }

    h6.question-prompt {
      font-weight: 400;
      margin-bottom: 1rem;
      text-transform: none;
      letter-spacing: normal;
      font-size: 0.96rem;

      @media #{$small-tablet} {
        font-size: 0.9rem;
      }

      // @media #{$small-tablet} {
      //   padding: 1rem;
      //   position: absolute;
      //   width: 25vw;
      //   left: 1rem;
      //   text-align: left;
      //   color: $text-color;
      //   margin-top: 1rem;
      //   border: solid 1px $border-color;
      //   border-radius: $border-radius;
      // }
    }
  }

  .error {
    color: $error;
  }
}
