.radio-question {
  display: flex;
  margin-bottom: $gutterR / 1.5;
  color: $text-color;

  &.hide-radio {
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
  }

  @media #{$small-tablet} {
    margin-bottom: $gutterR / 1.7;
  }

  &:active {
    input[type='radio'] + label {
      transform: translateY(2px);
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    left: -9999px;
  }

  .radio-letter {
    align-self: center;
    margin-right: 0.7rem;
    color: $secondary-color;
    font-weight: 600;
    text-transform: uppercase;
  }

  input[type='radio'] + label,
  input[type='checkbox'] + label {
    flex-basis: 100%;
    position: relative;
    padding-left: $check-size * 1.5;
    cursor: pointer;
    text-transform: none;
    font-weight: normal;
    letter-spacing: unset;
    margin: 0;
    font-size: $check-size / 1.6;
    font-weight: normal;
    display: block;
    border-radius: $border-radius;
    padding: 0.7rem;
    transition: all 0.25s;
    background-color: $light-grey;

    @media #{$small-tablet} {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    p {
      margin-bottom: 0;
    }
  }
  &.show-radio input[type='radio'] + label,
  &.show-radio input[type='checkbox'] + label {
    padding-left: 3rem;
    text-align: left;
  }

  input[type='radio']:checked + label,
  input[type='checkbox']:checked + label {
    background-color: $secondary-color;
    color: $white;
  }

  input[type='radio']:checked + label + .hint,
  input[type='checkbox']:checked + label + .hint {
    display: flex;
  }

  &:hover {
    input[type='radio'] + label,
    input[type='checkbox'] + label {
      background-color: $grey;
      opacity: 0.85;
      color: $black;
    }

    input[type='radio']:checked + label,
    input[type='checkbox']:checked + label {
      background-color: $secondary-color;
      color: $white;
    }
  }

  &.show-radio input[type='radio'] + label::before,
  &.show-radio [type='radio'] + label::after,
  &.show-radio input[type='checkbox'] + label::before,
  &.show-radio [type='checkbox'] + label::after {
    content: '';
    position: absolute;
    left: $check-size / 2;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid $grey;
    width: $check-size;
    height: $check-size;
    border-radius: 50%;
    color: $white;
    display: block;
    background-color: $grey;
    transition: all 0.25s;
  }

  [type='radio'] + label::after,
  [type='checkbox'] + label::after {
    // background-image is URL encoded SVG definition of images/controls/check-blue.svg
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 13' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EPath%3C/title%3E%3Cpath d='M5.775 9.616l-4.13-3.483-1.29 1.53 5.6 4.721 9.783-10.71L14.262.326z' fill='%23248FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 65% 65%;
    background-color: #f9f9fb;
    transition: all 0.25s;
    background-position: center center;
  }

  input[type='radio']:not(:checked) + label::after,
  input[type='checkbox']:not(:checked) + label::after {
    opacity: 0;
  }

  input[type='radio']:checked + label::after,
  input[type='checkbox']:checked + label::after {
    opacity: 1;
  }
}

// Question and answers laid out horizontally for horizontalImageSingleSelect answerTypes such as in the 'Spatial' subtest
.horizontal-question-answer-images {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  max-width: 810px;

  .radio-question.answer-image-type {
    flex-direction: column;
    margin-left: 0.8rem;

    @media (max-width: #{$tablet-max-width}), (max-height: 900px) {
      margin-left: 0.5rem;
    }
  }

  .radio-letter {
    margin-right: 0rem;
    margin-top: 0.5rem;
    font-size: 1.2rem;
  }

  input[type='radio'] + label::before,
  [type='radio'] + label::after,
  input[type='checkbox'] + label::before,
  [type='checkbox'] + label::after {
    left: 50%;
    top: $check-size / 2;
    transform: translateX(-50%);
  }

  input[type='radio'] + label,
  input[type='checkbox'] + label {
    $content-width: 100px;
    $content-height: 105px;
    $content-padding: 0.7rem;
    $top-padding: 2.6rem;

    width: calc(#{$content-width} + #{$content-padding} * 2);
    padding: $content-padding;
    padding-top: $top-padding;

    @media screen and (max-width: 810px) {
      $content-padding-small: 0.4rem;

      width: calc(#{$content-width} + #{$content-padding-small} * 2);
      padding: $content-padding-small;
      padding-top: $top-padding;
    }
  }
}
