@keyframes lower-in {
  0% {
    transform: translateY(-20%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.dropdown-trigger {
  &.more-btn {
    @extend .button-reset;
    width: 1.875rem;
    height: 1.875rem;
    background: url(/images/icons/icon-ellipsis-dark.svg) no-repeat center;
    background-size: 85%;
  }
}

.dropdown-component {
  display: block;
  z-index: -1;
  background-color: #fff;
  min-width: 240px;
  position: absolute;
  opacity: 0;
  border: solid 1px $border-color;
  margin-top: 10px;
  overflow: hidden;

  &.open {
    animation: lower-in 0.35s;
    opacity: 1;
    z-index: 10;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 1;
    list-style: none;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  li > a,
  li > button {
    @extend .button-reset;
    display: block;
    text-decoration: none;
    color: $text-color;
    background-repeat: no-repeat;
    background-position: 1rem 0.5rem;
    background-size: 1.25rem;
    padding: 0.75rem 1rem;
    font-size: 0.875rem;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: $light-blue;
      color: $text-color;
    }

    &:disabled,
    &.disabled {
      cursor: default;
      opacity: 0.5;

      &:hover {
        background-color: $light-grey;
      }
    }
  }

  li {
    display: flex;
    align-items: center;

    &.heading {
      color: $text-color;
      padding: 0.75rem 1rem;
      font-size: 0.9rem;
      font-weight: 600;
    }

    a {
      width: 100%;
    }

    .icon-wrap {
      display: inline-block;
      width: 0.7rem;
      height: 0.7rem;
      position: relative;
      margin-right: 0.75rem;

      svg.icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        width: 1rem;
        height: 1rem;
        stroke: currentColor;
        fill: transparent;

        &.fill-black {
          fill: $black;
        }
      }
    }
  }
}
